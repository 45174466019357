/**
 *
 * @file categoryAPI.js
 * @created_date Friday, July 28, 2023
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description Here we defined a set of functions related to category authentication for the application, These functions are organized within the categoryAPI object, which is then exported for use in other parts of the application. The code also includes the import of utility functions for managing access tokens and an API client.
 * <br/>Example use age of code - {@tutorial categoryAPI}
 * @module categoryAPI
 *
 **/
import apiClient from "../utils/apiClient";
import { getAuthAccessObject } from "../utils/tokenStorage";

/**
 * @description  This function sends a GET request to the /category/list endpoint to retrieve a list of categories. It includes the access token in the headers. It returns the response as a parsed JSON object if the request is successful.

 * @function getCategories
 * @return {void}
 * */
const getCategories = async () => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(`/category/list`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const categoryAPI = { getCategories };

export default categoryAPI;
