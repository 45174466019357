import React from "react";
import { withTranslation } from "react-i18next";
import ToggleButton from "../Misc/ToggleButton";
import MiscFunctions from "../../helpers/MiscFunctions";
class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmailCommentsActive: false,
      isEmailUpdatesActive: false,
      isAppCommentsActive: false,
      userObjekt: this.props.userMainObject,
    };
  }

  componentDidUpdate(prevState, prevProps) {
    if (prevProps.userMainObject !== this.props.userMainObject) {
      if (
        this.props.userMainObject.preferences.includes(
          "notifications_comment_app"
        ) &&
        !this.state.isAppCommentsActive
      ) {
        this.setState({ isAppCommentsActive: true });
      }
      if (
        this.props.userMainObject.preferences.includes("notifications") &&
        !this.state.isEmailCommentsActive
      ) {
        this.setState({ isEmailCommentsActive: true });
      }
      if (
        this.props.userMainObject.preferences.includes(
          "notifications_updates"
        ) &&
        !this.state.isEmailUpdatesActive
      ) {
        this.setState({ isEmailUpdatesActive: true });
      }
    }

    if (prevProps.userMainObject !== this.props.userMainObject) {
      if (
        !this.props.userMainObject.preferences.includes(
          "notifications_comment_app"
        ) &&
        this.state.isAppCommentsActive
      ) {
        this.setState({ isAppCommentsActive: false });
      }
      if (
        !this.props.userMainObject.preferences.includes("notifications") &&
        this.state.isEmailCommentsActive
      ) {
        this.setState({ isEmailCommentsActive: false });
      }
      if (
        !this.props.userMainObject.preferences.includes(
          "notifications_updates"
        ) &&
        this.state.isEmailUpdatesActive
      ) {
        this.setState({ isEmailUpdatesActive: false });
      }
    }
  }
  componentWillMount() {
    if(MiscFunctions.isNull(this.props.userMainObject.preferences)){
      this.props.userMainObject.preferences = [];
    } else{
      if (
        this.props.userMainObject.preferences.includes(
          "notifications_comment_app"
        )
      ) {
        this.setState({ isAppCommentsActive: true });
      }
      if (this.props.userMainObject.preferences.includes("notifications")) {
        this.setState({ isEmailCommentsActive: true });
      }
      if (
        this.props.userMainObject.preferences.includes("notifications_updates")
      ) {
        this.setState({ isEmailUpdatesActive: true });
      }
    }
  }

  render() {
    return (
      <>
        <div className="settings-notification-section">
          {this.props.activeSubmenu === "notification_within_app" && (
            <div className="notification-within-app">
              <span className="notification-label">
                {this.props.t("general.notification_within_app")}
              </span>
              <div className="notification-within-app-toggle-button">
                <span>{this.props.t("general.comments")}</span>
                <ToggleButton
                  isActive={this.state.isAppCommentsActive}
                  value={"notifications_comment_app"}
                  userObject={this.props.userMainObject}
                  hotRefreshUserObject={(object) =>
                    this.props.hotRefreshUserObject(object)
                  }
                />
              </div>
            </div>
          )}
          {this.props.activeSubmenu === "email_notification" && (
            <div>
              <span className="notification-label ">
                {this.props.t("general.email_notification")}
              </span>
              <div className="notification-within-app-toggle-button">
                <span>{this.props.t("general.comments")}</span>
                <ToggleButton
                  isActive={this.state.isEmailCommentsActive}
                  value={"notifications"}
                  userObject={this.props.userMainObject}
                  hotRefreshUserObject={(object) =>
                    this.props.hotRefreshUserObject(object)
                  }
                />
              </div>
              <div className="notification-within-app-toggle-button">
                <span>{this.props.t("general.updates_of_app")}</span>
                <ToggleButton
                  isActive={this.state.isEmailUpdatesActive}
                  value={"notifications_updates"}
                  userObject={this.props.userMainObject}
                  hotRefreshUserObject={(object) =>
                    this.props.hotRefreshUserObject(object)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
export default withTranslation()(Notification);
