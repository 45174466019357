/**
 *
 * @file timestampAPI.js
 * @created_date Friday, July 28, 2023
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description Here we defined a set of functions related to timestamp authentication for the application, These functions are organized within the timestampAPI object, which is then exported for use in other parts of the application. The code also includes the import of utility functions for managing access tokens and an API client.
 * <br/>Example use age of code - {@tutorial timestampAPI}
 * @module timestampAPI
 *
 **/
import { isRouteErrorResponse } from "react-router-dom";
import apiClient from "../utils/apiClient";
import { getAuthAccessObject } from "../utils/tokenStorage";

/**
 * @description This function sends a GET request to the /comment/:id/children endpoint to retrieve child comments of a parent comment. It includes the access token in the headers. It returns the response as a parsed JSON object if the request is successful.
 * @function getTimestampByID
 * @return {void}
 * */
const getTimestampByID = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: getAuthAccessObject().recaptcha,
    };
    return await apiClient
      .get(`/datetimestamp/${id}`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const postTimestamp = async (captchaValue, data) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: getAuthAccessObject().recaptcha,
    };

    return await apiClient
      .post(`/datetimestamp`, data, customHeaders)
      .then(async function (response) {
        if (response && response.status === 201) {
          return response.json();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const timestampAPI = {
  getTimestampByID,
  postTimestamp,
};

export default timestampAPI;
