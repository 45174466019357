/**
 * @file PersonGroupInfo.js
 * @created_date Thursday, November 30, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component renders information about person groups and associated persons. It uses the finalGroup prop to update its state and displays boxes for each person group, including the group name and a list of persons. The component ensures that it only renders when the infoBarObject is not null.
 *<br/>Breakdown of the code - {@tutorial PersonGroupInfo}
 * @module PersonGroupInfo
 *
 **/

import React from "react";
import Ressource from "../../services/RessourceServices";
import MiscFunctions from "../../helpers/MiscFunctions";
class PersonGroupInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infoBarObject: this.props.finalGroup,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.infoBarObject !== this.props.finalGroup) {
      this.setState({ infoBarObject: this.props.finalGroup });
    }
  }
  render() {
    return (
      !MiscFunctions.isNull(this.state.infoBarObject) && !MiscFunctions.isUndefined(this.state.infoBarObject) &&
      this.state.infoBarObject.map((el, i) => {
        return (
          <div className="workflow-side-persongroup-box" key={i}>
            <span className="workflow-persongroup-name">{el.label}</span>
            <div className="workflow-person-names-box">
              {el.persons.map((person, i) => {
                person = new Ressource(person);
                return (
                  <span className="workflow-person-name" key={i}>
                    {person.getLabel}
                  </span>
                );
              })}
            </div>
          </div>
        );
      })
    );
  }
}
export default PersonGroupInfo;
