import React from "react";
import InputField from "../Misc/InputField";
import MediaUploadUI from "../ContentInput/MediaUploadUI";
import AvataImage from "../../assets/img/icon_medical_expert_selected.svg";
import userAPI from "../../api/userAPI";
import { Spinner } from "flowbite-react";
import ToastMessages from "../../helpers/ToastMessages";
import { withTranslation } from "react-i18next";
import MiscFunctions from "../../helpers/MiscFunctions";

class PersonalData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortOptions: [
        { label: "English", tag: "eng", icon: "drop-down-menu-icon-eng" },
        { label: "German", tag: "de", icon: "drop-down-menu-icon-de" },
        { label: "Japanese", tag: "jp", icon: "drop-down-menu-icon-jp" },
      ],
      picture: null,
      inputProfessional:
        !MiscFunctions.isNull(this.props.userMainObject.titel)
          ? this.props.userMainObject.titel
          : "",
      inputName:
        !MiscFunctions.isNull(this.props.userMainObject.firstname)
          ? this.props.userMainObject.firstname
          : "",
      inputLastName:
        !MiscFunctions.isNull(this.props.userMainObject.lastname)
          ? this.props.userMainObject.lastname
          : "",
      inputProfession:
        !MiscFunctions.isNull(this.props.userMainObject.jobtitle)
          ? this.props.userMainObject.jobtitle
          : "",
      inputEmail:
        !MiscFunctions.isNull(this.props.userMainObject.email)
          ? this.props.userMainObject.email
          : "",
      inputPhone:
        !MiscFunctions.isNull(this.props.userMainObject.phone)
          ? this.props.userMainObject.phone
          : "",
      inputPreferences:
        !MiscFunctions.isNull(this.props.userMainObject.preferences)
          ? this.props.userMainObject.preferences
          : "",
      validEmail: !MiscFunctions.isNull(this.props.userMainObject.email) ? true : false,
      validPhone: !MiscFunctions.isNull(this.props.userMainObject.phone) ? true : false,
      AvataImage:
        !MiscFunctions.isNull(this.props.userMainObject.avatar)
          ? this.props.userMainObject.avatar
          : AvataImage,
      fileName: "",
      saveData: false,
      disabledSaveBtn: false,
    };
  }

  handleChange = (fieldName, value, error) => {
    if (fieldName === "inputEmail") {
      this.setState({ validEmail: error === "" });
    } else if (fieldName === "inputPhone") {
      this.setState({ validPhone: error === "" });
    }
    
    this.setState({ [fieldName]: value, disabledSaveBtn: false });
  };

  handleSubmit = (event) => {
    this.setState({ saveData: true });
    event.preventDefault();
    const {
      inputProfessional,
      inputName,
      inputLastName,
      inputProfession,
      inputPhone,
      inputEmail,
      inputPreferences,
    } = this.state;
    const newUserObject = {
      // ...this.props.userMainObject,
      id: this.props.userMainObject.id,
      titel: inputProfessional,
      firstname: inputName,
      lastname: inputLastName,
      jobtitle: inputProfession,
      phone: inputPhone,
      email: inputEmail,
      preferences: inputPreferences,
    };
    if (!MiscFunctions.isNull(this.state.picture)) {
      newUserObject["avatar"] = this.state.fileName;
    }
    userAPI.updateUserObject(newUserObject).then((res) => {
      this.props.hotRefreshUserObject(JSON.parse(res));
      this.setState({ saveData: false });
      ToastMessages.successMessage(
        this.props.t("messages.information_updated_successfully")
      );
      this.setState({ disabledSaveBtn: true });
    });
  };
  handlePicture = (elem, fileInfo) => {
    this.setState({
      fileName: fileInfo.name,
      picture: elem,
      disabledSaveBtn: false,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.validEmail !== this.state.validEmail) {
      this.setState({ validEmail: this.state.validEmail });
    }
    if (prevState.validPhone !== this.state.validPhone) {
      this.setState({ validPhone: this.state.validPhone });
    }
  }

  isSaveButtonDisabled = () => {
    return (
      // this.state.inputProfessional !== "" &&
      this.state.inputName !== "" &&
      this.state.inputLastName !== "" &&
      // this.state.inputProfession !== '' &&
      this.state.validEmail === true &&
      !this.state.disabledSaveBtn
      // this.state.validPhone === true
    );
  };

  render() {
    return (
      <>
        <div className="upload-media-block">
          <div className="selected-avatar-icon">
            <img
              src={
                !MiscFunctions.isNull(this.state.picture)
                  ? this.state.picture.url
                  : this.state.AvataImage
              }
              alt=""
            />
          </div>
          <MediaUploadUI
            label={this.props.t("general.change")}
            selectHandler={this.handlePicture}
            border={25}
            imageWidth={300}
            imageHeight={300}
            borderRadius={150}
            reScale={true}
            rotate={true}
            fileType={"image"} //image, doc, video
            description={false} //true, flase
          />
        </div>

        <div className="general-input-professional-section">
          <InputField
            type="text"
            name="inputProfessional"
            placeholder={this.props.t("general.professional_title")}
            value={this.state.inputProfessional}
            onChange={this.handleChange}
            border={false} //false or number
            borderRadius={false} //false or number
            boxShadow={false} //true or false
          />
        </div>

        <div className="general-input-name-lastname-section">
          <InputField
            type="text"
            name="inputName"
            placeholder={this.props.t("general.first_name")}
            value={this.state.inputName}
            onChange={this.handleChange}
            border={false} //false or number
            borderRadius={false} //false or number
            boxShadow={false} //true or false
          />
          <InputField
            type="text"
            name="inputLastName"
            placeholder={this.props.t("general.last_name")}
            value={this.state.inputLastName}
            onChange={this.handleChange}
            border={false} //false or number
            borderRadius={false} //false or number
            boxShadow={false} //true or false
          />
        </div>
        <div className="general-input-profession-section">
          <InputField
            type="text"
            name="inputProfession"
            placeholder={this.props.t("general.profession")}
            value={this.state.inputProfession}
            onChange={this.handleChange}
            border={false} //false or number
            borderRadius={false} //false or number
            boxShadow={false} //true or false
          />
        </div>
        <div className="general-input-phone-email-section">
          <InputField
            type="email"
            name="inputEmail"
            placeholder={this.props.t("general.email")}
            value={this.state.inputEmail}
            onChange={this.handleChange}
            border={false} //false or number
            borderRadius={false} //false or number
            boxShadow={false} //true or false
          />
          <InputField
            type="tel"
            name="inputPhone"
            placeholder="+49..."
            value={this.state.inputPhone}
            onChange={this.handleChange}
            border={false} //false or number
            borderRadius={false} //false or number
            boxShadow={false} //true or false
          />
        </div>
        {
          <button
            className="save-settings-btn"
            disabled={!this.isSaveButtonDisabled()}
            onClick={this.handleSubmit}
          >
            {this.state.saveData && (
              <Spinner color="success" aria-label="Saving" size="sm" />
            )}
            {this.props.t("general.save")}
          </button>
        }
      </>
    );
  }
}

export default withTranslation()(PersonalData);
