import MiscFunctions from "../helpers/MiscFunctions";
import Ressource from "./RessourceServices";

/**
 *
 * @file SegmentsServices.js
 * @created_date Friday, July 28, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This class is for handling the Segments-Array, it manages an array of "Segment" objects, providing methods to retrieve and manipulate segments of different types based on certain criteria. It is designed to handle collections of segments, such as phases or tasks, and to facilitate various operations on them.
 * <br/>Example use age of code - {@tutorial SegmentsServices}
 * @module SegmentsServices
 *
 **/
class Segments {
  /**
   * @description Creates an array of Segment-Objects.
   * @param {Segment} Array - Array of Segment-Objects.
   */
  constructor(Array) {
    this.Elements = Array;
  }

  //
  /**
   * @description Setter-Function to create the object from a json. Adds an additional Segment-Object
   * @return {Segments} Extended Segments-Array.
   */
  set addSegment(Segment) {
    if (MiscFunctions.isUndefined(Segment.error)) {
      this.Elements.push(Segment);
    }
  }

  //
  /**
   * @description Getter Function to extract every ProcessDto. Will return all segments of the array of the type phase.
   * @return {Segments} All Segments of type phase.
   */
  get getProcessElements() {
    const ProcessElements = this.Elements.filter(
      // ({ segmenttype }) => segmenttype === "ProcessDto"
      ({ segmenttype }) => {
        return segmenttype === "ProcessDto";
      }
    ).reverse();
    return ProcessElements;
  }

  //
  /**
   * @description Getter Function to extract every PhaseDto. Will return all segments of the array of the type phase.
   * @return {Segments} All Segments of type phase.
   */
  get getPhaseElements() {
    const PhaseElements = new Segments(
      this.Elements.filter(({ segmenttype, level }) => {
        return segmenttype === "PhaseDto";
      })
    );
    // console.log(PhaseElements)
    return PhaseElements;
  }

  //
  /**
   * @description Getter Function to extract every TaskDto. Will return all segments of the array of the type task.
   * @return {Segments} All Segments of type task.
   */
  get getTaskElements() {
    const TaskElements = new Segments(
      this.Elements.filter(
        ({ segmenttype }) => segmenttype === "TaskDto"
      ).reverse()
    );
    return TaskElements;
  }

  //
  /**
   * @description Getter Function to extract every Segment of the given level.
   * @return {Segments} All Segments of given level.
   */
  getElementsOfLevel(input) {
    const Elements = new Segments(
      this.Elements.filter(({ level }) => level === input).reverse()
    );
    return Elements;
  }

  //
  /**
   * @description Getter Function to extract all task related to a given persongroup. Will return all task associated with the given persongroup.
   * @param {elem} persongroup Persongroup-Object
   * @return {Segments} All Segments associated with the given persongroup.
   */
  getPersonGroupTasks(elem) {
    const PersonGroupTasks = new Segments(
      this.Elements.filter(({ persongroup, segmenttype }) => {
        if (!MiscFunctions.isNull(persongroup)) {
          return persongroup.indexOf(elem) !== -1 && segmenttype === "TaskDto";
        } else {
          return false;
        }
      })
    );
    return PersonGroupTasks;
  }

  /**
   * @description Will return all task associated with the given person.
   * @param {elem} person Person-Object
   * @return {Segments} All Segments associated with the given person.
   */
  getPersonTasks(elem) {
    let Person = new Ressource(elem);
    const tasks = this.getTaskElements.Elements;
    if (!MiscFunctions.isNull(Person) && !MiscFunctions.isUndefined(Person)) {
      let tasksFiltered = new Segments([]);

      tasks.map((task) => {
        if (
          task.segmenttype === "TaskDto" &&
          !MiscFunctions.isNull(task.ressources) &&
          !MiscFunctions.isUndefined(task.ressources)
        ) {
          task.ressources.filter((res) => {
            let ressource = new Ressource(res);
            if (ressource.getID === Person.getID) {
              tasksFiltered.addSegment = task;
            }
          });
        }
      });
      return tasksFiltered;
    }
    return null;
  }

  getGroupPersons(personsTasks, group) {
    if (!MiscFunctions.isNull(personsTasks) && !MiscFunctions.isUndefined(personsTasks)) {
      return personsTasks.filter((el) => {
        if (el.reference.persongroup.id.id === group.id) {
          return el;
        }
      });
    }
    return null;
  }

  /**
   * @description Will return all unique Person-Objects from the segment-collection.
   * @param {elem} person Person-Object
   * @return {Segments} All Segments associated with the given person.
   */
  getPersonsOfTasks(elem) {
    const task = this.getTaskElements.Elements;

    const Persons = [];
    const dummy = task.map((v, i, self) => {
      if (v.segmenttype === "TaskDto" && !MiscFunctions.isNull(v.ressources)) {
        let person = v.ressources.filter((elem) => {
          elem = new Ressource(elem);
          if (elem.getReferenceType === "PersonDto") return elem;
        });
        person.map((elem) => {
          elem = new Ressource(elem);
          if (
            MiscFunctions.isUndefined(Persons.find((person) => person.getID == elem.getID))
          ) {
            Persons.push(elem);
          }
        });
      }
    });
    return Persons;
  }

  /**
   * @description Will return all unique PersonGroup-Objects from the segment-collection.
   * @param {elem} person Person-Object
   * @return {Segments} All Segments associated with the given person.
   */
  getPersongroupsOfTasks(elem) {
    const task = this.getTaskElements.Elements;

    const Persongroups = [];
    const dummy = task.map((v, i, self) => {
      if (v.segmenttype === "TaskDto" && !MiscFunctions.isNull(v.ressources)) {
        let person = v.ressources.filter(
          (elem) => elem.reference.type === "PersonDto"
        );
        person.map((elem) => {
          // console.log(elem.reference.persongroup)
          // console.log(Persons.find((person) =>  person.id.id == elem.id.id))
          if (
            MiscFunctions.isUndefined(
              Persongroups.find(
                (persongroup) => persongroup.id === elem.reference.persongroup.id
              )
            )
          ) {
            Persongroups.push(elem.reference.persongroup);
          }
        });
      }
    });
    return Persongroups;
  }

  getChildren(elem) {
    const children = new Segments(
      this.Elements.filter(({ level, parent }) => {
        return parent != null && elem.id === parent.id;
      })
    );
    return children;
  }

  findParentById(childId) {
    for (let i = 0; i < this.Elements.length; i++) {
      if (this.Elements[i].id === childId) {
        return this.Elements[i];
      }
    }
    return null;
  }
}

export default Segments;