/**
 * @file ActionsMenu.js
 * @created_date Thursday, November 30, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component represents a dropdown menu with an associated button. It manages the visibility of the menu, the selected option, and provides callbacks for handling option clicks. The component receives options and pre-selection information as props.
 *<br/>Breakdown of the code - {@tutorial ActionsMenu}
 * @module ActionsMenu
 *
 **/

import React, { Component } from "react";
import MiscFunctions from "../../helpers/MiscFunctions";

class ActionsMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNavVisible: false,
      selectedOption: this.props.preSelection.label,
    };
    // this.options = this.props.options;
  }

  toggleNav = () => {
    this.setState({ isNavVisible: !this.state.isNavVisible });
  };

  handleOptionClick = (option) => {
    this.setState({ selectedOption: option.label });
    this.props.onClick(option);
    this.toggleNav();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.options !== this.props.options ||
      prevProps.preSelection !== this.props.preSelection
    ) {
      this.setState({ selectedOption: this.props.preSelection.label });
      console.log(this.props.preSelection)

    }
  }

  render() {
    // console.log(this.props.options)
    return (
      <div className="drop-down-menu-with-icon">
        <button className="drop-down-menu-btn" onClick={this.toggleNav}>
          <span className={"drop-down-menu-icon-bg " + this.props.icon}></span>
          <span className="drop-down-menu-text">
            {this.state.selectedOption}
          </span>
          <span className="drop-down-menu-icon-arrow"></span>
        </button>
        {this.state.isNavVisible && (
          <nav className="drop-down-options">
            {!MiscFunctions.isNull(this.props.options) &&
              this.props.options.map((option, index) => (
                <li
                  className="drop-down-option"
                  key={index}
                  onClick={() => this.handleOptionClick(option)}
                >
                  {option.label}
                </li>
              ))}
          </nav>
        )}
      </div>
    );
  }
}

export default ActionsMenu;
