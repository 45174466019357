/**
 * @file WorkflowBar.js
 * @created_date Wednesday, August 01, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component, brings together various sub-components to create a comprehensive interface for managing the workflow visualization.
 *<br/>Breakdown of the component - {@tutorial WorkflowBar}
 * @module WorkflowBar
 *
 **/

import React from "react";
import TimelineGlobal from "./TimelineGlobal";
import SwimlaneContainer from "./SwimlaneContainer";
import SearchField from "../Misc/SearchField";
import ActionsMenu from "../Misc/ActionsMenu";
class WorkflowBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zoomLevel: this.props.zoomLevel,
      rangeSliderValue: this.props.rangeSliderValue,
      searchKeyword: null,
      isSearching: false,
      sortOptions: this.props.workflowParameters.person,
      sortOption: null,
    };
  }

  // handlingZoomScroll(newZoomLevel, rangeValue) {
  //   if (newZoomLevel === null) {
  //     this.setState({
  //       rangeSliderValue: rangeValue,
  //     });
  //   } else {
  //     this.setState({
  //       zoomLevel: newZoomLevel,
  //       rangeSliderValue: rangeValue,
  //     });
  //   }
  // }

  handleSortOptionChange(e) {
    this.setState({ sortOption: e });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.WorkflowTasks !== this.props.WorkflowTasks) {
      this.setState({ WorkflowTasks: this.props.WorkflowTasks });
    }
    if (prevProps.zoomLevel !== this.props.zoomLevel) {
      this.setState({ zoomLevel: this.props.zoomLevel });
    }
    if (prevProps.rangeSliderValue !== this.props.rangeSliderValue) {
      this.setState({ rangeSliderValue: this.props.rangeSliderValue });
    }
  }

  render() {
    return (
      <>
        <div className="workflow-bar">
          {/* <TimelineGlobal
            player={this.props.player}
            handlingZoomScroll={(newZoomLevel, rangeValue) => {
              this.handlingZoomScroll(newZoomLevel, rangeValue);
            }}
            zoomLevel={this.state.zoomLevel}
            rangeSliderValue={this.state.rangeSliderValue}
            ProgressBarPosition={this.props.ProgressBarPosition}
          /> */}
          {/* <div className="swimlane-header"> */}
          {/* <SearchField
              stateHandler={(input) => {
                this.setState({
                  searchKeyword: input,
                  isSearching: input !== "",
                });
              }}
              iconDirection={"left"}
            />
            <ActionsMenu
              options={this.state.sortOptions}
              onClick={(e) => {
                this.handleSortOptionChange(e);
              }}
              icon={"drop-down-menu-icon-sort"}
            /> */}
          {/* </div> */}
          <SwimlaneContainer
            videoLabel={this.props.videoLabel}
            WorkflowPhases={this.props.WorkflowPhases}
            WorkflowTasks={this.props.WorkflowTasks}
            zoomLevel={this.state.zoomLevel}
            player={this.props.player}
            rangeSliderValue={this.state.rangeSliderValue}
            handlingZoomScroll={(rangeValue) => {
              this.props.handlingZoomScroll(null, rangeValue);
            }}
            ProgressBarPosition={this.props.ProgressBarPosition}
            workflowParameters={this.props.workflowParameters}
            searchKeyword={this.state.searchKeyword}
            isSearching={this.state.isSearching}
            sortOption={this.state.sortOption}
            showIntroPopup={this.props.showIntroPopup}
            handleOpenIntroPopup={this.props.handleOpenIntroPopup}
            videoID={this.props.videoID}
            selectedVideo={this.props.selectedVideo}
          />
        </div>
      </>
    );
  }
}

export default WorkflowBar;
