/**
 *
 * @file app.js
 * @created_date Thursday, December 15, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @description This is the main component file in the application, it acts as a container for all other components.
 * @Copyright © 2022 mediMESH. All rights reserved.
 *
 **/

import "./main.css";
import LandingPage from "./components/LandingPage";

function App() {
  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;
