/**
 *
 * @file MediaUploadUI.js
 * @created_date Friday, July 28, 2023
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component streamlines the media upload process by presenting users with a button that triggers a modal for file selection. Users can preview and describe the chosen image, with real-time feedback. The component leverages the DigitalOcean API to obtain the upload link, ensuring a seamless and efficient upload experience.
 * <br/>Example use age of code - {@tutorial MediaUploadUI}
 * @module MediaUploadUI
 *
 **/
import React from "react";
import mediaAPI from "../../api/mediaAPI";
import DOClient from "../../utils/DOClient";
import TextInput from "./TextInput";
import { Modal, Spinner } from "flowbite-react";
import AvatarEditor from "react-avatar-editor";
import Counter from "./Counter";
import { withTranslation } from "react-i18next";
import { getAuthAccessObject } from "../../utils/tokenStorage";
import ToastMessages from "../../helpers/ToastMessages";
import MiscFunctions from "../../helpers/MiscFunctions";

class MediaUploadUI extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // put state variables here
      selectedFile: null,
      selectedFileSrc: null,
      showBlock: false,
      uploadSuccess: false,
      mediaDescription: "",
      showSpinner: false,
      rotateValue: 0,
      scaleValue: 1,
      EditedFile: null,
      selectedVideo: null,
      imageType: ".jpg,.jpeg,.png",
      docType: ".pdf",
      videoType: ".mp4",
      selectedFileType: ".jpg,.jpeg,.png,.pdf,.mp4", //incase no fileType added
    };
  }

  dataURLtoFile(dataurl, filename, fileType) {
    var arr = dataurl.split(","),
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: fileType });
  }

  handleDescription = (string) => {
    this.setState({ mediaDescription: string });
  };

  selectFileHandler = (event) => {
    this.setState({
      selectedFile: null,
      selectedFileSrc: null,
      editedFile: null,
      selectedVideo: null,
    });
    this.setState({ selectedFile: event.target.files[0] });
    this.handleImageChange(event);
  };

  renameFile(originalFile, newName) {
    const newFile = new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });

    return newFile;
  }

  uploadMedia = () => {
    const originalFileName = this.state.selectedFile.name;
    if (originalFileName) {
      this.setState({ showSpinner: true });
      const extension = originalFileName.slice(originalFileName.lastIndexOf("."));
      // let nameWithoutExtension = originalFileName.replace(extension, "");
      // nameWithoutExtension = nameWithoutExtension.toLowerCase();
      const timestamp = Date.now();
      // const fileName = `${nameWithoutExtension}-${timestamp}${extension}`;
      const fileName = `${getAuthAccessObject().ID}-${timestamp}${extension}`;
      const privateFileName = `${getAuthAccessObject().ID}/${getAuthAccessObject().ID}-${timestamp}${extension}`;
      const newFile = this.renameFile(this.state.selectedFile, privateFileName);
      let editedFile = newFile;
      if (this.editor) {
        editedFile = this.dataURLtoFile(
          this.editor.getImageScaledToCanvas().toDataURL(),
          newFile.name,
          this.state.selectedFile.type
        );
        this.setState({ selectedFile: editedFile });
      }
      const res = mediaAPI.getUploadLink(
        fileName,
        this.state.mediaDescription
      );
      res.then(async (response) => {
        try {
          let media = await DOClient.put(
            response.message,
            editedFile,
            this.state.mediaDescription
          ).then(
            async function (response) {
              const mediaObject = await mediaAPI.getMediaObject(
                newFile.name,
                response.description
              );
              return mediaObject;
            },
            async (err) => {
              console.log(err);
            }
          );
          
          this.props.selectHandler(media, newFile);
          this.setState({
            uploadSuccess: true,
            showSpinner: false,
            showBlock: false,
          });
        } catch (e) {
          console.error("Error: ", e);
        }
      });
    }
    else {
      ToastMessages.erorrMessage(this.props.t('messages.select_a_file'));
    }
  };

  componentDidMount() {
    if (this.props.fileType === "image")
      this.setState({ selectedFile: ".jpg,.jpeg,.png" });
    else if (this.props.fileType === "doc")
      this.setState({ selectedFile: ".pdf" });
    else if (this.props.fileType === "video")
      this.setState({ selectedFile: ".mp4" });
  }
  componentDidUpdate(prevProps, prevState) {
    // if (prevState.selectedFile !== this.state.selectedFile) {
    //   this.setState({ selectedFile: this.state.selectedFile });
    // }
  }

  handleImageChange = (event) => {
    this.setState({ uploadSuccess: false });

    const file = event.target.files[0];

    if (file && file.type.startsWith("video/")) {
      const videoUrl = URL.createObjectURL(file);
      this.setState({ selectedVideo: videoUrl });
    }

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.setState({
          selectedFileSrc: e.target.result,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  setEditorRef = (editor) => (this.editor = editor);

  onScaleChange = (e) => {
    this.setState({ scaleValue: Number(e) });
    this.setState({ uploadSuccess: false });
  };

  onRotateChange = (e) => {
    this.setState({ rotateValue: Number(e) });
    this.setState({ uploadSuccess: false });
  };

  isFileImage = (file) => {
    return file && file.split("/")[0] === "image";
  };

  bodyOverflow = () => {
    document.body.style.overflow="";
  }

  render() {
    return (
      <div
        className="flex flex-row mb-2 list-input-main-block"
        style={{
          minHeight: "60px",
        }}
      >
        {this.state.showBlock ? (
          <Modal
            show={this.state.showBlock}
            className="expiration-box"
            onClose={() => {
              this.setState({ showBlock: !this.state.showBlock });
            }}
          >
            <Modal.Body
              className="text-center"
              style={{ overflow: "inherit", flex: "none" }}
            >
              <div className="file_upload_box">
                <span
                  className="button_modal_close"
                  onClick={() => this.setState({ showBlock: false })}
                ></span>
                <div className="flex flex-col text-sm">
                  <input
                    type="file"
                    name="file"
                    accept={this.state.selectedFile}
                    onChange={(e) => this.selectFileHandler(e)}
                  />
                </div>

                {!MiscFunctions.isNull(this.state.selectedFile) &&
                  !MiscFunctions.isUndefined(this.state.selectedFile) && (
                    <>
                      {this.isFileImage(this.state.selectedFile.type) && (
                        <div className="image-edit-block">
                          <AvatarEditor
                            ref={this.setEditorRef}
                            style={{ width: "80%", height: "80%" }}
                            image={this.state.selectedFileSrc}
                            color={[0, 0, 0, 0.6]} // RGBA
                            scale={this.state.scaleValue}
                            rotate={this.state.rotateValue}
                            border={this.props.border}
                            width={this.props.imageWidth}
                            height={this.props.imageHeight}
                            borderRadius={this.props.borderRadius}
                          />
                          <div
                            className={"ml-10 mr-10"}
                            style={{
                              minWidth: "100px",
                              marginLeft: "15px",
                              height: "auto",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <label
                              className={
                                "block text-sm font-medium text-gray-900 dark:text-white input_label"
                              }
                            >
                              {this.state.selectedFile.name}
                            </label>
                          </div>

                          <div className="image-edit-settins-block">
                            {this.props.rotate === true && (
                              <Counter
                                title="Rotate"
                                value={this.state.rotateValue}
                                min={-180}
                                max={180}
                                onChange={this.onRotateChange}
                              />
                            )}
                            {this.props.reScale === true && (
                              <>
                                <Counter
                                  title="Scale"
                                  value={this.state.scaleValue}
                                  min={1}
                                  max={10}
                                  onChange={this.onScaleChange}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}

                      {this.state.selectedVideo && (
                        <video
                          controls
                          src={this.state.selectedVideo}
                          width="640"
                        >
                          Your browser does not support the video tag.
                        </video>
                      )}

                      {this.props.description && <div className="flex flex-col">
                        <TextInput
                          label=""
                          rows="5"
                          limit="50"
                          setValue={(e) => this.handleDescription(e)}
                          value={this.state.mediaDescription}
                        />
                      </div>}
                      <button
                        className="content-upload-media-btn"
                        onClick={this.uploadMedia}
                        disabled={this.state.uploadSuccess ? true : false}
                      >
                        {this.state.showSpinner && (
                          <Spinner
                            style={{ height: "30px", "margin-right": "5px" }}
                            color="success"
                            aria-label="loading comments"
                            size="sm"
                          />
                        )}
                        {this.state.uploadSuccess ? this.props.t('general.done') : this.props.t('general.upload')}
                      </button>
                    </>
                  )}
              </div>
            </Modal.Body>
          </Modal>
          ) : this.bodyOverflow()
        }
        <div
          className="flex flex-row"
          style={{
            minHeight: "inherit",
          }}
        >
          <button
            onClick={() => this.setState({ showBlock: !this.state.showBlock })}
            className="content-upload-media-btn"
          >
            {this.props.label}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MediaUploadUI);
