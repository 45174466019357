/**
 * @file TextInput.js
 * @created_date Thursday, September 30, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description This component is a React class-based component for rendering a textarea input. It includes state variables to manage input focus, a ref to the textarea element, and lifecycle methods.
 *<br/>Breakdown of the code - {@tutorial TextInput}
 * @module TextInput
 *
 **/

import React from "react";
import MiscFunctions from "../../helpers/MiscFunctions";

class TextInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input: true, // This state is monitoring if a change of value is origining from the textarea itself or from external
      // put state variables here
    };
    this.textareaRef = React.createRef();
  }

  onClickOutsideListener = () => {};

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    // console.log("Update: textarea")
    // console.log('Active Element:', document.activeElement);
    // Focus is set to the textarea if it's input is changing
    if (
      prevProps.value !== this.props.value &&
      prevState.input !== this.state.input
    ) {
      this.textareaRef.current.focus();
    }
  }

  render() {
    return (
      <div className="input_group_item">
        <label
          className={
            "block mt-2 mb-1 text-sm font-medium text-gray-900 dark:text-white input_label"
          }
        >
          {this.props.label}
        </label>
        {/* <input type="text" 
            onChange={(e) => this.props.setValue(e.target.value)} 
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-mainYellowColor focus:border-mainYellowColor block w-60 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required /> */}
        <textarea
          ref={this.textareaRef}
          className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-mainYellowColor focus:border-mainYellowColor block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          rows={!MiscFunctions.isUndefined(this.props.rows) ? this.props.rows : "5"}
          maxLength={!MiscFunctions.isUndefined(this.props.limit) ? this.props.limit : "250"}
          placeholder={
            "Description (max." +
            (!MiscFunctions.isUndefined(this.props.limit) ? this.props.limit : "250") +
            " characters)"
          }
          value={this.props.value}
          onChange={(e) => {
            this.setState({ input: !this.state.input });
            this.props.setValue(e.target.value);
          }}
        ></textarea>
      </div>
    );
  }
}

export default TextInput;
