/**
 * @file WorkflowLibraryService.js
 * @created_date Wednesday, August 23, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This file is created to manage WorkflowLibrary-related data in the application. The class has properties and methods to handle various aspects of the WorkflowLibrary, such as its ID, label, categories, video ID, and description.
 *<br/>Breakdown of the component - {@tutorial WorkflowLibraryService}
 * @module WorkflowLibraryService
 *
 **/

import MiscFunctions from "../helpers/MiscFunctions";

class WorkflowLibraryService {
  constructor(input) {
    this.id = input.id;
    this.accessible = input.accessible;
    this.label = input.label;
    this.description =
      !MiscFunctions.isUndefined(input.description) ? input.description : null;
    this.picture = input.picture;
    this.thumbnail = input.thumbnail;
    this.workflows = input.workflows;
  }

  get getWorkflowLibraryID() {
    return this.id.id;
  }

  get getAccessbile() {
    return this.accessible;
  }

  get getLabel() {
    return this.label;
  }

  get getDescription() {
    return this.description;
  }

  get getPicture() {
    return this.picture;
  }
  get getThumbnail() {
    return this.thumbnail;
  }
  get getWorkflows() {
    return this.workflows;
  }
}

export default WorkflowLibraryService;
