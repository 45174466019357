/**
 * @file WorkflowService.js
 * @created_date Wednesday, August 23, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This file is created to manage workflow-related data in the application. The class has properties and methods to handle various aspects of the workflow, such as its ID, label, categories, video ID, and description.
 *<br/>Breakdown of the component - {@tutorial WorkflowBar}
 * @module WorkflowBar
 *
 **/

import MiscFunctions from "../helpers/MiscFunctions"

class Workflow {
  constructor(input) {
    this.id = input.id;
    this.label = input.label;
    this.favorited = !MiscFunctions.isUndefined(input.favorited) ? null: input.favorited;
    this.categories = !MiscFunctions.isUndefined(input.categories) ? null: input.categories;
    this.company = input.company;
    this.contexts = !MiscFunctions.isNull(input.contexts) ? input.contexts: null;
    this.creationDate = input.creationDate;
    this.lastChanged = input.lastChanged;
    this.group = input.group;
    this.videoID = input.videoID;
    this.description =
      !MiscFunctions.isNull(input.description) ? input.description : null;
    this.picture = input.picture;
    this.thumbnail = input.thumbnail;
    this.videos = input.videos;
    this.selectedVideo = input.selectedVideo;
  }

  get getWorkflowID() {
    return this.id.id;
  }

  get getLabel() {
    return this.label;
  }

  get getCategories() {
    return this.categories;
  }

  get getCompany() {
    return this.company;
  }

  get getContexts() {
    return this.contexts;
  }

  get getCreationDate() {
    return this.creationDate;
  }

  getCategoryByName = (catname) => {
    const category = this.categories
      .filter((cat) => cat.label === catname)
      .reverse();
    if (category.length < 1) return -1;
    return category;
  };

  get getDescription() {
    return this.description;
  }

  get getVideoID() {
    return this.getSelectedVideo[0].id.id;
  }

  get getVideos() {
    return this.videos;
  }

  set setSelectedVideo(selectedVideo) {
    this.selectedVideo = selectedVideo;
  }

  get getSelectedVideo() {
    return [this.selectedVideo];
  }

  //
  /**
   * @description Getter-Function to retrieve the json required for POST/PUT-Request
   * @return {String} JSON
   **/
  get json() {
    let workflow = {
      id: this.id,
      label: this.label,
      videos: this.videos.map((video) => {
        return video.id;
      }),
    };

    if (!MiscFunctions.isNull(this.context)) {
      workflow["contexts"] = this.contexts.map((context) => {
        return context;
      });
    }
    // if (this.categoryies!==null){
    //   workflow["category"] = this.categories.map((category)=>{return category.id});
    // };
    if (!MiscFunctions.isNull(this.description)) {
      workflow["description"] = this.description;
    }

    return workflow;
  }
}

export default Workflow;
