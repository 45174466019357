/**
 * @file WorkflowInfoInput.js
 * @created_date Saturday, November 12, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description The purpose of this component is to create the Context-Object for Workflowinfo
 * @module WorkflowInfoInput
 *
 **/

import React from "react";
import contextAPI from "../../api/contextAPI";
import workflowAPI from "../../api/workflowAPI";
import Workflow from "../../services/WorkflowService";
import VideoService from "../../services/VideoService";

import TextInput from "./TextInput";
import { Card } from "flowbite-react";
import videoAPI from "../../api/videoAPI";
import { withTranslation } from "react-i18next";
import MiscFunctions from "../../helpers/MiscFunctions";

class WorkflowInfoInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // put state variables here
      label: "",
      abstract: "",
      intro: "",
      indication: "",
      contraindication: "",
      preparation: "",
      care: "",
      workflow: null,
    };
  }

  emptyValue = {
    label: "",
    tag: "empty",
  };

  handleLabel = (elem) => {
    this.setState({ label: elem });
  };
  handleAbstract = (elem) => {
    this.setState({ abstract: elem });
  };
  handleIntro = (elem) => {
    this.setState({ intro: elem });
  };
  handleIndication = (elem) => {
    this.setState({ indication: elem });
  };
  handleContraIndication = (elem) => {
    this.setState({ contraindication: elem });
  };
  handlePreparation = (elem) => {
    this.setState({ preparation: elem });
  };
  handleCare = (elem) => {
    this.setState({ care: elem });
  };

  handleSubmit = async () => {
    let video = this.state.video;

    const resContext = await contextAPI.postContext({
      string1: this.state.abstract,
      string2: this.state.intro,
      string3: this.state.indication,
      string4: this.state.contraindication,
      string5: this.state.preparation,
      string6: this.state.care,
      type: "Workflow-Introduction",

      mapping:
        '{"string1": "abstract","string2": "intro","string3": "indication","string4": "contraindication","string5": "preparation","string6": "care"}',
    });
    const dataContext = await JSON.parse(resContext);
    let repeat = false;
    if (MiscFunctions.isNull(video.contexts)) {
      video.contexts = [dataContext.id];
    } else {
        video.contexts.map(async (i) => {
          if (i.id !== dataContext.id.id) {
            repeat = false;
          }else{
            repeat = true;
          }
        })
      if(!repeat){
        video.contexts.push(dataContext.id);
            const respVideo = await videoAPI.changeVideo(video.json);
      }
    }
  };

  componentDidMount() {
    const res = workflowAPI.getWorkflowByID(this.props.workflow.id);
    res.then(async (response) => {
      if (!MiscFunctions.isUndefined(response)) {
        let workflow = new Workflow(JSON.parse(await response));
        this.setState({ workflow: workflow });
      }
    });

    const vid = videoAPI.getVideoById(this.props.workflow.selectedVideo.id.id);
    vid.then(async (response) => {
      let video = new VideoService(await response);
      this.setState({ video: video });
    });
  }

  render() {
    return (
      <Card className="max-w-full mt-2">
        <div className="">
          <div>
            {/* <StringInput
                    label="Name"
                    setValue={this.handleName}
                    value={this.state.label}
                  /> */}
            <TextInput
              label="Abstract"
              rows="9"
              limit="550"
              setValue={this.handleAbstract}
              value={this.state.abstract}
            />
            <TextInput
              label="Introduction"
              rows="9"
              limit="550"
              setValue={this.handleIntro}
              value={this.state.intro}
            />
            <TextInput
              label="Indication"
              rows="9"
              limit="550"
              setValue={this.handleIndication}
              value={this.state.indication}
            />
            <TextInput
              label="Contraindication"
              rows="9"
              limit="550"
              setValue={this.handleContraIndication}
              value={this.state.contraindication}
            />
            <TextInput
              label="Preoperative Preparation"
              rows="9"
              limit="550"
              setValue={this.handlePreparation}
              value={this.state.preparation}
            />
            <TextInput
              label="Postoperative Care"
              rows="9"
              limit="700"
              setValue={this.handleCare}
              value={this.state.care}
            />

            <button
              onClick={() => {
                this.handleSubmit();
              }}
              className="content-admin-save-btn"
              disabled={
                !this.state.abstract ||
                !this.state.intro ||
                !this.state.indication ||
                !this.state.contraindication ||
                !this.state.preparation ||
                !this.state.care
                  ? true
                  : false
              }
            >
              {" "}
              {this.props.t("general.enter")}
            </button>
          </div>
        </div>
      </Card>
    );
  }
}

export default withTranslation()(WorkflowInfoInput);
