/**
 *
 * @file mentionsInputStyle_Annotation.js
 * @created_date Thursday, December 15, 2022
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The component provides a set of styles that can be applied to control components, multi-line inputs, single-line inputs, and suggestion lists within a web application. It allows customization of various aspects such as size, font, color, and positioning to achieve the desired visual and interactive effects.
 * <br/>Breakdown of the code - {@tutorial MentionInputStyle_Annotation}
 * @module MentionInputStyle_Annotation
 **/

export default {
  control: {
    marginTop: "15px",
    backgroundColor: "#eee",
    fontSize: "12px",
    zIndex: 0,
    fontFamily: "Poppins Regular, sans-serif",
    position: "relative",
  },

  "&multiLine": {
    display: "flex",
    flexDirection: "column",
    control: {
      fontFamily: "Poppins Regular, sans-serif",
      minHeight: 60,
    },
    highlighter: {
      lineHeight: "inherit",
      overflow: "hidden",
      padding: 9,
      border: "1px solid transparent",
      height: 150,
    },
    input: {
      overflowY: "auto",
      lineHeight: "inherit",
      padding: 9,
      border: "1px solid silver",
      height: 150,
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: "12px",
      zIndex: 1,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#add8e6",
      },
    },
  },
};
