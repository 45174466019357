import apiClient from "../utils/apiClient";

/**
 * @description This function sends a POST request to the /patient/loginl endpoint to request authoriziation for patient video. It includes the reCAPTCHA value in the headers. It returns the response object.
 * <br />Here you can find some examples: - {@tutorial patientLogin}
 * @function patientLogin
 * @return {void}
 * */
const patientLogin = async (captchaValue, username, loginPassword) => {
  const bodyData = {
    username: username,
    userpassword: loginPassword,
  };

  try {
    const customHeaders = {
      recaptcha: captchaValue,
    };
    return await apiClient
      .post(`/patient/login`, bodyData, customHeaders)
      .then(async (data) => {
        let resJson = await data.text();
        let result = {
          token: resJson,
          status: data.status,
          recaptcha: captchaValue,
        };
        return result;
      });
  } catch (err) {
    return err;
  }
};

/**
 * @description This function sends a GET request to the /patientvideo endpoint with the patient access token in the Authorization header. It is used to get patient video.
 * <br />Here you can find some examples: - {@tutorial patientVideo}
 * @function patientVideo
 * @return {void}
 * */
const patientVideo = async (accessToken) => {
  const customHeaders = {
    Authorization: `Bearer ${accessToken}`,
  };
  try {
    return await apiClient
      .get(`/patientvideo`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          const data = await resp.message;
          return data;
        }
      });
  } catch (err) {
    console.log("Error: ", err);
  }
};

const patientAPI = {
  patientLogin,
  patientVideo,
};

export default patientAPI;
