/**
 * @file QuickOverviewContent.js
 * @created_date Thursday, December 21, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component is a React class-based component responsible for rendering the content of a quick overview. It includes state variables to manage content updates and lifecycle methods.
 *<br/>Breakdown of the code - {@tutorial QuickOverviewContent}
 * @module QuickOverviewContent
 *
 **/

import React from "react";
import QuickOverviewTabs from "./QuickOverviewTabs";
import MiscFunctions from "../../helpers/MiscFunctions";
import { withTranslation } from "react-i18next";

class QuickOverviewContent extends React.Component {
  constructor(props) {
    super();
    this.state = {
      // dataset: props,
      content: props.content,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.content !== this.props.content) {
      this.setState({ content: this.props.content });
    }
  }

  render() {
    return (
      <div
        className="drop-down-content-block"
        style={
          this.props.contentType === "infos"
            ? { background: "#f2f2f2", border: "1px solid #D9D9D9" }
            : {}
        }
      >
        <div className="drop-down-left-content-block">
          {this.props.contentType === "infos" &&
            this.state.content.map((el, i) => {
              return (
                <div key={i}>
                  <h2 className="drop-down-left-content-heading">{el.label}</h2>
                  <p className="drop-down-left-content-text">
                    {el.description}
                  </p>
                </div>
              );
            })}

          {this.props.contentType === "resources" &&
            (!MiscFunctions.isNull(this.props.content) && !MiscFunctions.isNull(this.props.content.tabs) ? (
              <QuickOverviewTabs tabs={this.props.content} />
            ) : 
              <div className="noInfoMessage">
                {this.props.t('messages.no_info_message')}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(QuickOverviewContent);
