/**
 *
 * @file videoAPI.js
 * @created_date Friday, July 28, 2023
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description Here we defined a set of functions related to video authentication for the application, These functions are organized within the videoAPI object, which is then exported for use in other parts of the application. The code also includes the import of utility functions for managing access tokens and an API client.
 * <br/>Example use age of code - {@tutorial videoAPI}
 * @module videoAPI
 *
 **/
import apiClient from "../utils/apiClient";
import { getAuthAccessObject } from "../utils/tokenStorage";

/**
 * @description This function sends a GET request to the /video/:id/segment endpoint to retrieve video processing information. It includes the access token in the headers. It returns the response as text if the request is successful.
 * <br />Here you can find some examples: - {@tutorial videoAPI}
 * @function getVideoProccessById
 * @return {void}
 * */
const getVideoProccessById = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(`/video/${id}/segment`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          const data = await resp.message;
          return data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description This function sends a GET request to the /video/:id/segment/PhaseDto/json endpoint to retrieve video processing information. It includes the access token in the headers. It returns the response as text if the request is successful.
 * <br />Here you can find some examples: - {@tutorial videoAPI}
 * @function getWorkflowPhasesJsonById
 * @return {void}
 * */
const getWorkflowPhasesJsonById = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(`/video/${id}/segment/PhaseDto/json`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          const data = await JSON.parse(resp.message);
          return data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};
/**
 * @description This function sends a GET request to the /video/{id}/segment/TaskDto/json endpoint to retrieve video processing information. It includes the access token in the headers. It returns the response as text if the request is successful.
 * <br />Here you can find some examples: - {@tutorial videoAPI}
 * @function getWorkflowTasksJsonById
 * @return {void}
 * */
const getWorkflowTasksJsonById = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(`/video/${id}/segment/TaskDto/json`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          const data = await JSON.parse(resp.message);
          return data;
        }

      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description This function sends a GET request to the /video/:id endpoint to retrieve video information. It includes the access token in the headers. It returns the response as a parsed JSON object if the request is successful.
 * <br />Here you can find some examples: - {@tutorial videoAPI}
 * @function getVideoById
 * @return {void}
 * */
const getVideoById = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(`/video/${id}`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description This function sends a PUT request to the /workflow endpoint to change it's content in the DB. It includes the access token and reCAPTCHA value in the headers. It returns the response object.
 * <br />Here you can find some examples: - {@tutorial videoAPI}
 * @function changeWorkflow
 * @return {void}
 * */
const changeVideo = async (data) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: getAuthAccessObject().recaptcha,
    };
    return await apiClient
      .put(`/video`, data, customHeaders)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description This function sends a GET request to the /video/:id/interactions endpoint to retrieve video interaction information. It includes the access token in the headers. It returns the response as text if the request is successful.
 * <br />Here you can find some examples: - {@tutorial videoAPI}
 * @function getVideoInteractionsById
 * @return {void}
 * */
const getVideoInteractionsById = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(`/video/${id}/interactions`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          const data = await resp.message;
          return data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description This function sends a GET request to the /video/:id/interactions/json endpoint to retrieve video interaction information as json. It includes the access token in the headers. It returns the response as text if the request is successful.
 * <br />Here you can find some examples: - {@tutorial videoAPI}
 * @function getVideoInteractionsJsonById
 * @return {void}
 * */
const getVideoInteractionsJsonById = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(`/video/${id}/interactions/json`, customHeaders)
      .then(async function (response) {

        // console.log(JSON.parse(response.message))
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          const data = await JSON.parse(resp.message);
          return data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description This function sends a GET request to the /video/:id/ressources endpoint to retrieve all ressources of this video. It includes the access token in the headers. It returns the response as text if the request is successful.
 * <br />Here you can find some examples: -
 * @function getVideoRessourcesById
 * @return {void}
 * */
const getVideoRessourcesById = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(`/video/${id}/ressources`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description This function sends a GET request to the /video/list endpoint to retrieve all videos. It includes the access token in the headers. It returns the response as text if the request is successful.
 * <br />Here you can find some examples: -
 * @function getVideoList
 * @return {void}
 * */
const getVideoList = async () => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(`/video/list`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const videoAPI = {
  getVideoProccessById,
  getWorkflowPhasesJsonById,
  getWorkflowTasksJsonById,
  changeVideo,
  getVideoById,
  getVideoInteractionsById,
  getVideoInteractionsJsonById,
  getVideoRessourcesById,
  getVideoList,
};

export default videoAPI;
