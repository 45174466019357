/**
 *
 * @file mediaAPI.js
 * @created_date Friday, July 28, 2023
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description Here we defined a set of functions related to media authentication for the application, These functions are organized within the mediaAPI object, which is then exported for use in other parts of the application. The code also includes the import of utility functions for managing access tokens and an API client.
 * <br/>Example use age of code - {@tutorial mediaAPI}
 * @module mediaAPI
 *
 **/
import apiClient from "../utils/apiClient";
import { getAuthAccessObject } from "../utils/tokenStorage";

/**
 * @description This function sends a GET request to the /video/:id/process endpoint to retrieve video processing information. It includes the access token in the headers. It returns the response as text if the request is successful.
 * <br />Here you can find some examples: - {@tutorial mediaAPI}
 * @function getVideoProccessById
 * @return {void}
 * */
const getUploadLink = async (filename, description) => {
  if (description === "") description = filename;
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(`/upload/${filename}/${description}`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description This function sends a GET request to the /video/:id/process endpoint to retrieve video processing information. It includes the access token in the headers. It returns the response as text if the request is successful.
 * <br />Here you can find some examples: -
 * @function getMediaObject
 * @return {void}
 * */
const getMediaObject = async (filename, description) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: getAuthAccessObject().recaptcha,
    };
    return await apiClient
      .get(`/success/upload/${filename}`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const mediaAPI = {
  getUploadLink,
  getMediaObject,
};

export default mediaAPI;