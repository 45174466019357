/**
 * @file Ressource_InputCard.js
 * @created_date Thursday, September 30, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description This component serves as a dynamic input form for creating ressources associated with persons, rooms, or tools. Users can input a name, select a template from a list of available options, describe the ressource, and upload an associated picture.
 *<br/>Breakdown of the code - {@tutorial Ressource_InputCard}
 * @module Ressource_InputCard
 *
 **/

import React from "react";
import ressourceObjectAPI from "../../api/ressourceObjectAPI";
import ressourceAPI from "../../api/ressourceAPI";
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/solid";

import StringInput from "./StringInput";
import ListInput from "./ListInput";
import MediaUploadUI from "./MediaUploadUI";
import { withTranslation } from "react-i18next";
import MiscFunctions from "../../helpers/MiscFunctions";

class Ressource_PersonInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // put state variables here
      name: "",
      description: "",
      level: "",
      picture: null,
      ressource: null,
      ressources: null,
      reset: false,
      sendSuccess: null,
    };
  }

  emptyValue = {
    label: "",
    tag: "empty",
  };

  handlePicture = (elem) => {
    this.setState({ picture: elem });
  };

  handleDescription = (string) => {
    console.log(string);
    this.setState({ description: string });
  };
  handleName = (string) => {
    this.setState({ name: string });
  };
  handleRessource = (elem) => {
    this.setState({ ressource: elem });
  };

  handleSendSuccess = (e) => {
    this.setState({ sendSuccess: e });
  };

  handleSubmit = async () => {
    this.setState({ saveData: true });
    let data = {
      label: this.state.name,
      description: this.state.description,
      reference: this.state.ressource.id,
      picture:
        MiscFunctions.isNull(this.state.picture)
          ? "logo_mediMESH_original_medium.png"
          : this.state.picture.url,
      thumbnail: MiscFunctions.isNull(this.state.picture)
        ? "logo_mediMESH_original_medium.png"
        : this.state.picture.url,
    };

    const res = ressourceAPI.postRessource(this.props.captchaValue, data);
    res
      .then((data) => {
        console.log(data.status);
        if (data.status === 200 || data.status === 201) {
          this.setState({
            saveData: false,
          });
          this.handleDescription("");
          this.handleName("");
          this.handleSendSuccess(true);
        } else {
          this.handleSendSuccess(false);
        }
      })
      .then(async (response) => {
        if (response && response.status === 201) {
          // let resJson = await response.text();
          // let result = { response: JSON.parse(resJson), status: response.status }
          return response.status;
        } else if (response && response.status !== 201) {
          console.log(response);
        }
      });
  };

  onClickOutsideListener = () => { };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedElement !== this.props.selectedElement) {
      this.setState({
        ressources: null,
        reset: !this.state.reset,
      });

      let res = null;
      switch (this.props.selectedElement) {
        case "person":
          res = ressourceObjectAPI.getPersons();
          break;
        case "room":
          res = ressourceObjectAPI.getRooms();
          break;
        case "tool":
          res = ressourceObjectAPI.getTools();
          break;
        default:
          console.log("Unbekannter Tag");
      }
      res.then(async (response) => {
        console.log(response);
        this.setState({ ressources: await response });
      });
    }
  }
  componentDidMount() {
    let res = null;
    switch (this.props.selectedElement) {
      case "person":
        res = ressourceObjectAPI.getPersons();
        break;
      case "room":
        res = ressourceObjectAPI.getRooms();
        break;
      case "tool":
        res = ressourceObjectAPI.getTools();
        break;
      default:
        console.log("Unbekannter Tag");
    }
    res.then(async (response) => {
      console.log(response);
      this.setState({ ressources: response });
    });
  }
  componentWillUnmount() { }

  render(

  ) {
    return (
      <div>
        <StringInput
          label={this.props.t('general.name')}
          setValue={this.handleName}
          value={this.state.name}
        />
        <ListInput
          label={this.props.t('general.template')}
          Elements={this.state.ressources}
          selectHandler={this.handleRessource}
          Reset={this.state.reset}
          Ready={!MiscFunctions.isNull(this.state.ressources)}
          preSelection={this.emptyValue}
        />
        {/* <TextInput
          label="Description"
          rows="5"
          limit="500"
          setValue={this.handleDescription}
          value={this.state.description}
        /> */}
        <MediaUploadUI label={this.props.t('general.picture')} selectHandler={this.handlePicture} />
        <button
          onClick={() => {
            this.handleSubmit();
          }}
          className="content-admin-save-btn"
          disabled={!(!MiscFunctions.isNull(this.state.ressource) && this.state.name !== "")}
        >
          {this.props.t('general.enter')}
        </button>
        {this.state.sendSuccess === true && (
          <CheckBadgeIcon className="h-6 w-6" />
        )}
        {this.state.sendSuccess === false && (
          <XCircleIcon className="h-6 w-6" />
        )}
      </div>
    );
  }
}

export default withTranslation()(Ressource_PersonInput);
