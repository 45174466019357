/**
 *
 * @file apiClient.js
 * @created_date Monday, November 13, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description The apiClient module is a versatile utility for making HTTP requests in a React application. It supports common CRUD operations (GET, POST, PUT, DELETE) and includes error handling.
 * <br/>Example use age of code - {@tutorial apiClient}
 * @module apiClient
 *
 **/

import authAPI from "../api/authAPI";

// This statement load the main API url form .env file (application rool dir).
const API_BASE_URL = process.env.REACT_APP_API_URL;

const apiClient = {
  get: async (url, customHeaders = {}) => {
    const headers = {
      "Content-Type": "application/json",
      ...customHeaders,
    };

    return await fetch(API_BASE_URL + url, {
      method: "GET",
      headers,
    })
      .then((response) => {
        if (response.status === 401) {
          authAPI.logout();
        }
        if (response.status === 404) {
          return response;
        }
        if (!response.ok) {
          throw new Error("Network error or API request failed");
        }
        return response;
      })
      .catch((error) => {
        console.error("API request failed:", error);
        throw error;
      });
  },

  post: async (url, data, customHeaders = {}) => {
    const headers = {
      "Content-Type": "application/json",
      ...customHeaders,
    };

    return await fetch(API_BASE_URL + url, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 401) {
          authAPI.logout();
        }

        if (response.status === 409) {
          return response;
        }
        if (!response.ok) {
          throw new Error("Network error or API request failed");
        }
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },

  put: async (url, data, customHeaders = {}) => {
    const headers = {
      "Content-Type": "application/json",
      ...customHeaders,
    };

    return await fetch(API_BASE_URL + url, {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 401) {
          authAPI.logout();
        }
        if (!response.ok) {
          throw new Error("Network error or API request failed");
        }
        return response.text();
      })
      .catch((error) => {
        console.error("API request failed:", error);
        throw error;
      });
  },

  delete: async (url, customHeaders = {}) => {
    const headers = {
      "Content-Type": "application/json",
      ...customHeaders,
    };

    return await fetch(API_BASE_URL + url, {
      method: "DELETE",
      headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network error or API request failed");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("API request failed:", error);
        throw error;
      });
  },
};

export default apiClient;
