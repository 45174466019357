import React from "react";
import { withTranslation } from "react-i18next";
import MiscFunctions from "../../helpers/MiscFunctions";

class InputField extends React.Component {
  state = {
    error: "",
    class: this.props.class,
    passwordValid: false,
    type: this.props.type,
    passwordValidityMsg: this.props.passwordValidityMsg,
  };

  validateInput = (value) => {
    const { type } = this.state;
    switch (type) {
      case "email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : this.props.t('messages.enter_valid_email');
      case "tel":
        return /^\+?[1-9]([-\s().]*\d){9,14}$/.test(value)
          ? ""
          : this.props.t('messages.enter_valid_phone');
      // case "password":
      //   return value === true ? "" : this.props.t('messages.not_fulfill_conditions');
      default:
        return "";
    }
  };

  handleChange = (event) => {
    const { passwordValid, type, passwordValidityMsg } = this.state;
    const { name, value } = event.target;
    const errorMessage = this.validateInput(value);
    this.props.onChange(name, value, errorMessage);
    if (type === "email" && type === "tell") {
      const errorMessage = this.validateInput(value);
      this.setState({ error: errorMessage });
      if (value.length < 1) this.setState({ error: "" });
    }
    else if (type === "password" && passwordValidityMsg === true) {
      const errorMessage = this.validateInput(passwordValid);
      this.setState({ error: errorMessage });
      if (value.length < 1) this.setState({ error: "" });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.class !== this.props.class) {
      this.setState({ class: this.props.class });
    }
    if (prevState.passwordValid !== this.props.passwordValid) {
      this.setState({ passwordValid: this.props.passwordValid });
      if (this.props.passwordValid === false) {
        const errorMessage = this.validateInput(this.props.passwordValid);
        this.setState({ error: errorMessage });
      }
      if (this.props.passwordValid === true) this.setState({ error: "" });
    }
    if (prevState.type !== this.props.type) {
      this.setState({ type: this.props.type });
    }
  }
  render() {
    const { type, name, value, placeholder, border, borderRadius, boxShadow } = this.props;
    const { error } = this.state;
    const style = {
      outline: border ? `${border}px solid rgba(204, 206, 216, 1)` : '',
      borderRadius: borderRadius ? `${borderRadius}px` : '',
      width: '300px',
      boxShadow: boxShadow ? '0px 1px 4px 0px rgba(0, 0, 0, 0.25)' : ''

    }
    return (
      <div className="general-input-field-block">
        <input
          style={style}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={(e) => this.handleChange(e)}
          className={
            !MiscFunctions.isNull(this.state.class)
              ? this.state.class + " general-input-field"
              : "general-input-field"
          }
        />
        {error && (
          <div className="general-input-field-invalid-error">{error}</div>
        )}
      </div>
    );
  }
}

export default withTranslation()(InputField);
