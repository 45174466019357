/**
 * @file Menu.js
 * @created_date Thursday, December 21, 2022
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The purpose of this component is to create a dropdown menu with selectable categories. It provides a user interface for selecting categories and manages the state of the menu, including the focus state, display state, and the list of available categories.
 *<br/>Breakdown of the code - {@tutorial Menu}
 * @module Menu
 *
 **/

import React, { useEffect, useState } from "react";
import { Icon_Click, Icon } from "./Icon";

class DropDownMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // put state variables here
      focus: false,
      display: false,
      ElemList: [...this.props.Elements],
    };

    this.ElemListCopy = [...this.props.Elements];
  }

  // const [focusObjectState, setFocusObjectState] = useState(false);
  // const [focusObjectCategory, setFocusObjectCategory] = useState("category_normal");
  // const [showCategories, setshowCategories] = useState(false);
  // const [CategoryList, setCategoryList] = useState(props.filterCategories);

  // const selCat = props.selected_categories;

  // const setFocus = () => {
  //     setFocusObjectState(true);
  //     setFocusObjectCategory("category_focus");
  // }

  // const resetFocus = () => {
  //     if (!showCategories) {
  //         setFocusObjectState(false);
  //         setFocusObjectCategory("category_normal");
  //     }
  // }

  // const displayCategories = () => {
  //     setshowCategories(!showCategories);
  // }

  toggleCategoryState = (name, state) => {
    //console.log("Triggered:" + name );
    this.ElemListCopy.map((item) => {
      if (name === item.label) {
        item.status = state;
      }
      return item;
    });
    console.log(this.ElemListCopy);
    this.setState({ ElemList: [...this.ElemListCopy] });
    this.props.selectEvent(this.state.ElemList);
  };

  onClickOutsideListener = () => {
    if (this.state.display) {
      document.removeEventListener("click", this.onClickOutsideListener);
      this.setState({ display: !this.state.display });
    }
  };

  componentWillUnmount() {
    console.log("Unmount");
    // this.myRef.current = null;
    // console.log(this.myRef)
    // this.setState({
    //     status: null,
    //     Replies: null,
    //     CommentText: null
    // })
  }

  render() {
    return (
      <div className="flex flex-col mt-2">
        <div
          className="flex items-center select_cat_block"
          style={{ width: "30%" }}
          onMouseEnter={() => {
            this.setState({ focus: true });
          }}
          onMouseLeave={() => {
            this.setState({ focus: false });
            if (this.state.display) {
              document.addEventListener("click", this.onClickOutsideListener);
            }
          }}
          onClick={() => {
            this.setState({ display: !this.state.display });
          }}
        >
          <div
            className={this.state.focus ? "category_focus" : "category_normal"}
          >
            {"Category"}
          </div>
          {this.state.focus && <div className="icon_up ml-1 comment_card_icon_arrow"></div>}
        </div>
        {/* Rendering the selected categories of the comment */}
        <div className="category_display">
          {this.state.ElemList.map((elem, i) => {
            if (elem.status) {
              return (
                <Icon
                  category={elem}
                  key={i}
                  changeState={this.toggleCategoryState}
                />
              );
            }
          })}
        </div>
        {/* Rendering the availabe categories of the comment */}
        {this.state.display && (
          <div
            className="block category_block_select mt-1"
            onMouseLeave={() => {
              document.addEventListener("click", this.onClickOutsideListener);
            }}
            onMouseEnter={() => {
              document.removeEventListener(
                "click",
                this.onClickOutsideListener
              );
            }}
          >
            <div className="flex flex-wrap text-sm">
              {/* <hr className="border-gray-300 " /> */}
              {this.state.ElemList.map((elem, i) => {
                return (
                  <Icon_Click
                    category={elem}
                    changeState={this.toggleCategoryState}
                    keyName={i}
                    key={i}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DropDownMenu;
