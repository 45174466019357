/**
 * @file AnnotationCommentCard.js
 * @created_date Thursday, December 21, 2022
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component represents a comment card for an annotation. It includes methods to display and hide the comment, handle replies to the comment, and update the state accordingly. The component renders the comment box with its headline, creation and change dates, user avatar, categories, comment text, and a reply input field. It also fetches and displays any replies to the comment asynchronously. The component utilizes various dependencies and external services for features such as mentions, avatars, comment services, and authentication.
 *<br/>Breakdown of the code - {@tutorial AnnotationCommentCard}
 * @module AnnotationCommentCard
 *
 **/
import React from "react";

// This imports the functional component from the previous sample.
import { Mention, MentionsInput } from "react-mentions";
import { Spinner } from "flowbite-react";
import MiscFunctions from "../../helpers/MiscFunctions";
import mentionsInputStyle_Annotation from "../mentionsInputStyle_Annotation";
import mentionStyle from "../mentionStyle";
import Avatar from "../Misc/Avatar";
import Comment from "../../services/CommentServices";
import commentAPI from "../../api/commentAPI";
import Interactions from "../../services/InteractionsServices";
import { withTranslation } from "react-i18next";

class AnnotationCommentCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // put state variables here
      status: this.props.elem.status,
      replyLoaded: false,
      Replies: [],
      CommentText: "",
      atDetect: false,
      Tags: [],
      cursorPos: 0,
      userMentions: this.props.companyUsers,
    };
    // this.handleClick = this.clickDisplayComment.bind(this);
    // this.handleClick = this.clickHideComment.bind(this);
    // this.handleClick = this.reply2Comment.bind(this);

    this.handlePageClick = this.handlePageClick.bind(this);

    this.myRef = React.createRef();
  }

  // Callback-Routine to switch off the comment diplay next to the annotation
  clickHideComment = () => {
    this.setState({ status: false });
  };

  // Callback-Routine to switch on the comment diplay next to the annotation
  clickDisplayComment = (e) => {
    e.stopPropagation();
    if (this.myRef.current.classList.contains("FadeInAndOut")) {
      this.myRef.current.classList.remove("FadeInAndOut");
    }
    this.setState({ status: true });
    this.props.closeComment();
  };

  reply2Comment = (comm, captchaValue) => {
    // console.log(comm)
    // console.log(captchaValue)
    let num = 1;
    if (typeof this.state.Replies !== "undefined") {
      num = this.state.Replies.getLength + 1;
    }
    // Consistent labeling of replies: Reply - Number of reply - headline of parent-comment
    let headline = MiscFunctions.checkCommentText(
      "Reply - " + num + " - " + comm.getHeadline
    );
    let content = MiscFunctions.checkCommentText(this.state.CommentText);

    let comment = new Comment({
      content: content,
      headline: headline,
    });
    comment.setVideoID = this.props.videoID;
    comment.setUserID = this.props.user.UserID;

    comment.reply = comm;
    console.log("comment: ", comment.json);

    const resComm = commentAPI.postComment({
      comment: comment.json,
      timestamp: comm.getTimeStampSeconds,
    });
    resComm.then(async (data) => {
      if (data.status === 201) {
        this.setState({ replyLoaded: false });
        this.props.onReply();
        this.setState({ CommentText: "" });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.status !== prevState.status && this.state.status === true) {
      // Set replyLoaded back if element is opened
      this.setState({ replyLoaded: false });
      this.props.player.current.pause();
    }
  }

  componentDidMount() {
    let containerAnnotation = this.props.containerAnnotationRef.current;
    containerAnnotation.addEventListener("click", this.handlePageClick);
  }

  componentWillUnmount() {
    this.myRef.current = null;
    this.setState({
      status: null,
      Replies: null,
      CommentText: null,
    });

    let containerAnnotation = this.props.containerAnnotationRef.current;
    containerAnnotation &&
      containerAnnotation.removeEventListener("click", this.handlePageClick);
  }

  handlePageClick(ev) {
    //e.target.classList.contains("medimesh-video-player")
    if (ev.target.classList.contains("medimesh-video-player")) {
      if (this.state.status) {
        this.setState({ status: false });
      }
    }
  }

  render() {
    if (this.state.status) {
      // Find the corresponding comment
      let val = new Comment(
        this.props.passVideoCaption.find((o) => o.id === this.props.elem.id)
      );

      let getAddedCommentLeftBoxClientX = 35;
      let getAddedCommentTopBoxClientX = 0;
      if (parseInt(this.props.elem.left) > 75)
        getAddedCommentLeftBoxClientX = -222;

      if (parseInt(this.props.elem.top) > 75)
        getAddedCommentTopBoxClientX = -80;

      if (!this.state.replyLoaded) {
        const childs = commentAPI.getCommentChildrenById(val.getID);
        childs.then(async (data) => {
          let dummy = await data;
          let Replies = new Interactions([]);
          if (!MiscFunctions.isUndefined(dummy)) {
            dummy.map((elem) => {
              Replies.addComment = new Comment(elem);
            });
          }
          this.setState({ replyLoaded: true });
          this.setState({ Replies: Replies });
        });
      }

      return (
        <div id={"ann_box_" + val.getID}>
          <div
            style={this.props.elem}
            onClick={() => {
              this.clickHideComment();
            }}
            id={"ann_" + val.getID}
            className="comment_anno_indicator"
          ></div>
          <div
            id={"comm_" + val.getID}
            className="annotation_comment_box"
            style={{
              transform:
                "translate(" +
                getAddedCommentLeftBoxClientX +
                "px," +
                getAddedCommentTopBoxClientX +
                "px)",
              left: this.props.elem.left,
              top: this.props.elem.top,
              position: "absolute",
            }}
          >
            <div className="annotation_comment_box_headline">
              <div className="annotation_comment_box_headline_title">
                {val.getHeadline}
              </div>
              <div className="annotation_comment_box_created">
                {val.getDateCreatedFormat}
              </div>
              <div className="annotation_comment_box_changed">
                {val.getDateChangedFormat}
              </div>
              <Avatar avatar={val.getUserAvatar} name={val.getUserName} />
            </div>
            <div className="flex mt-2 selected_tags">
              {/* Rendering the categories of the comment */}
              {val.getCategories.map((cat, j) => {
                return (
                  //p-1 mx-1
                  <div
                    className={cat.icon + "BgColor selected_tag_container flex"}
                    key={j}
                  >
                    <span
                      className={"icon_" + cat.icon + " all-category-icon"}
                      key={j}
                    ></span>
                  </div>
                );
              })}
            </div>
            <div className="annotation_comment_box_text">
              {MiscFunctions.formatCommentText(val.getContent)}
            </div>
            <div className="annotation_comment_text">
              {this.props.t("general.activity")}
            </div>
            <div className="annotation_comment_box_reply">
              <MentionsInput
                //inputRef={"Reply_input_"+val.id}
                placeholder={this.props.t("messages.add_comment_message")}
                value={this.state.CommentText}
                onChange={(ev) =>
                  this.setState({ CommentText: ev.target.value })
                }
                style={mentionsInputStyle_Annotation}
                a11ySuggestionsListLabel={"Suggested mentions"}
                className="mention-input"
                //customSuggestionsContainer={(children) => {return(<div><span style={{fontWeight: "bold"}}><h2>This container has customised suggestions</h2></span>{children.map((elem) => {return(<div>{elem.display}</div>)})}</div>)}}
              >
                <Mention
                  trigger="@"
                  data={this.state.userMentions}
                  displayTransform={(id, display) => `${display}`}
                  style={mentionStyle}
                  markup="@[__display__,__id__]"
                  renderSuggestion={(elem) => {
                    return (
                      <div className="flex flex-row">
                        <div className="suggestion_comment_box_user_image">
                          <span
                            style={{
                              backgroundImage: `url(${elem.avatar})`,
                              width: "25px",
                              height: "25px",
                              display: "block",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              borderRadius: "50%",
                            }}
                          ></span>
                        </div>
                        <div style={{ alignItems: "center", display: "flex" }}>
                          <span>{elem.display}</span>
                        </div>
                      </div>
                    );
                  }}
                />
              </MentionsInput>
              <div
                onClick={() => this.reply2Comment(val)}
                className="button_annotation_commment_box_reply"
              >
                {" "}
                {this.props.t("general.send")}{" "}
              </div>
            </div>
            {!this.state.replyLoaded && (
              <div className="flex flex-wrap flex-col gap-2 pt-5 text-center marg">
                <Spinner
                  color="success"
                  aria-label="loading comments"
                  size="xl"
                />
              </div>
            )}
            {this.state.replyLoaded && (
              <div className="annotation_comment_box_replies">
                {this.state.Replies.getLength > 0 &&
                  this.state.Replies.Elements.map((reply, i) => {
                    if (typeof reply !== "undefined") {
                      return (
                        <div
                          id={"reply_" + reply.getID}
                          style={{
                            display: "flex",
                            marginTop: "3px",
                            marginRight: "5px",
                          }}
                          key={i}
                        >
                          {/* <div style={{ width: "15%", marginTop: "6px" }}>
                            <div className="annotation_comment_box_user_image">
                              <img
                                src={reply.getUserAvatar}
                                alt=""
                                className="comment_user_profile"
                              />
                            </div>
                          </div> */}

                          <div className="suggestion_comment_box_user_image">
                            <span
                              style={{
                                backgroundImage: `url(${reply.getUserAvatar})`,
                                width: "25px",
                                height: "25px",
                                display: "block",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                borderRadius: "50%",
                              }}
                            ></span>
                          </div>
                          <div style={{ width: "85%" }}>
                            <div className="annotation_comment_box_reply_headline">
                              {reply.getUserName +
                                " " +
                                this.props.t("general.wrote") +
                                ":"}
                            </div>
                            <div className="annotation_comment_box_reply_text">
                              {MiscFunctions.formatCommentText(
                                reply.getContent
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div
          ref={this.myRef}
          onAnimationEnd={(e) => {
            this.clickDisplayComment(e);
          }}
          style={this.props.elem}
          onClick={(e) => {
            this.clickDisplayComment(e);
          }}
          id={"ann_" + this.props.elem.id}
        >
          <input type="hidden" value="" />
        </div>
      );
    }
  }
}

export default withTranslation()(AnnotationCommentCard);
