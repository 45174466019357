/**
 *
 * @file segmentAPI.js
 * @created_date Friday, July 28, 2023
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description Here we defined a set of functions related to segment authentication for the application, These functions are organized within the segmentAPI object, which is then exported for use in other parts of the application. The code also includes the import of utility functions for managing access tokens and an API client.
 * <br/>Example use age of code - {@tutorial segmentAPI}
 * @module segmentAPI
 *
 **/
import apiClient from "../utils/apiClient";
import { getAuthAccessObject } from "../utils/tokenStorage";

/**
 * @description This function sends a POST request to the /segment endpoint to post a segment. It includes the access token and reCAPTCHA value in the headers. It returns the response object.
 * <br />Here you can find some examples: - {@tutorial segmentAPI}
 * @function postSegment
 * @return {void}
 * */
const postSegment = async (captchaValue, data) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: getAuthAccessObject().recaptcha,
    };

    return await apiClient
      .post(`/segment`, data, customHeaders)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description This function sends a PUT request to the /segment endpoint to change a segment in the DB. It includes the access token and reCAPTCHA value in the headers. It returns the response object.
 * <br />Here you can find some examples: - {@tutorial segmentAPI}
 * @function changeSegment
 * @return {void}
 * */
const changeSegment = async (captchaValue, data) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: captchaValue,
    };

    return await apiClient
      .put(`/segment`, data, customHeaders)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const getSegmentByVideoID = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(`/segment/list/${id}`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const getSegmentByID = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(`/segment/${id}`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const getSegmentTypeJsonByID = async (id, segmenttype) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(`/video/${id}/segment/${segmenttype}/json`, customHeaders)
      .then(async function (response) {
          const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          const data = await JSON.parse(resp.message);
          return data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const segmentAPI = {
  postSegment,
  getSegmentByVideoID,
  changeSegment,
  getSegmentByID,
  getSegmentTypeJsonByID,
};

export default segmentAPI;
