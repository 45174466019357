/**
 *
 * @file TaskInfoCard.js
 * @created_date Thur, April 25, 2024
 * @author Jiaying He <jiayinghe@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The component renders the Info-Cards of workflow phases and workflow tasks.
 *<br/>Key functionalities - 
 * @module TaskInfoCard
 *
 **/
 import React from "react";
 import Ressource from "../../services/RessourceServices";
 import MiscFunctions from "../../helpers/MiscFunctions";
 class TaskInfoCard extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            pos: this.props.pos,
            taskRight: this.props.taskRight,
            
        }
    }

    render(){
        return(
            <>
            <div
            style={{
              left: `${(this.props.pos + this.props.taskRight)}%`,
            }}
            className="swimlane-info-card-block"
          >
            <span
              className={"swimlane-task-info-card"}
              style={{
                bottom: `${this.props.topValue}px`,
              }}
              ref={this.props.taskInfoCardRef}
            >
              {this.props.showToolbox && (
                <div className="swimlane-info-card-tool-box-details">
                  <span className="swimlane-info-card-tool-box-details-title">
                    <span style={{ width: "85%" }}>
                      {this.props.selectedTool
                        ? this.props.selectedTool.getLabel
                        : "Tool name"}
                    </span>

                    <span
                      className="button_modal_close closeBtn"
                      onClick={() => {
                        this.setState({
                          showToolbox: false,
                          selectedTool: null,
                        });
                      }}
                    ></span>
                  </span>
                  <span className="swimlane-info-card-body">
                    <span className="swimlane-info-card-tag-list">
                      {this.props.selectedTool.getQuantity !== 0 && (
                        <span className="swimlane-info-card-tag">
                          {this.props.selectedTool.getQuantity}
                        </span>
                      )}
                      {!MiscFunctions.isNull(this.props.selectedTool.getDisposable) && (
                        <span className="swimlane-info-card-tag">
                          {this.props.selectedTool.getDisposable
                            ? "Disposable"
                            : "Non Disposable"}
                        </span>
                      )}
                    </span>
                    <span className="swimlane-info-card-tool-box-details-discription">
                      {this.props.selectedTool
                        ? this.props.selectedTool.getDescription
                        : "Tool description"}
                    </span>
                  </span>
                </div>
              )}
              <div className="swimlane-info-card-content-box">
                <span className="swimlane-info-card-block-arrow"></span>
                <span className="swimlane-info-card-heading">
                  <span className="swimlane-info-card-task-label">
                    {this.props.selectedTaskInfo.getLabel}
                  </span>

                  <span className="swimlane-task-duration">
                    <span className="swimlane-task-start-time">
                      <span className="swimlane-task-duration-icon"></span>
                      {
                        this.props.selectedTaskInfo.getTimestamp.split(".")[0]
                      }{" "}
                    </span>

                    <span className="swimlane-task-end-time">
                      <span className="swimlane-time-arrow-icon"></span>
                      {
                        MiscFunctions.secondsMinuteFormat(
                          Math.ceil(
                            this.props.selectedTaskInfo.getTimeStampSeconds +
                            this.props.selectedTaskInfo.getDuration
                          )
                        ).split(".")[0]
                      }
                    </span>
                  </span>
                </span>
                <span className="swimlane-info-card-tag-list">
                  {!MiscFunctions.isUndefined(this.props.selectedTaskInfo.getSterility) && (
                    <span className="swimlane-info-card-tag">
                      {this.props.selectedTaskInfo.getSterility
                        ? "Steril"
                        : "Non Steril"}
                    </span>
                  )}
                  {!MiscFunctions.isNull(this.props.selectedTaskInfo.getAnatomical) &&
                    this.props.selectedTaskInfo.getAnatomical !== "" && (
                      <span className="swimlane-info-card-tag">
                        {this.props.selectedTaskInfo.getAnatomical}
                      </span>
                    )}
                </span>
                <span className="swimlane-info-card-task-description">
                  {this.props.selectedTaskInfo.getDescription}
                </span>
                <div className="swimlane-info-card-tool-box">
                  {this.props.tools &&
                    this.props.selectedTaskInfo.ressources
                      .filter(
                        ({ reference }) =>
                          reference.type === "ToolDto" &&
                          reference.category === "tool"
                      )
                      .slice(0, 3)
                      .map((tool, i) => {
                        tool = new Ressource(tool);
                        return (
                          <span
                            key={i}
                            className={
                              !MiscFunctions.isNull(this.props.selectedTool) &&
                                this.props.selectedTool.getID === tool.getID
                                ? "swimlane-info-card-tool-box-item-selected swimlane-info-card-tool-box-item"
                                : "swimlane-info-card-tool-box-item"
                            }
                            onClick={() => this.handleSelectedTool(tool)}
                          >
                            <span className="swimlane-info-card-tool-box-item-label">
                              {tool.getLabel}
                            </span>
                            <span className="swimlane-info-card-tool-box-item-category">
                              {tool.getReference.category}
                            </span>
                            {/* <span className="swimlane-info-card-tool-box-item-play">
                            Play
                          </span> */}
                          </span>
                        );
                      })}
                </div>
              </div>
            </span>
          </div>
            </>
        )
    }
}
export default TaskInfoCard;