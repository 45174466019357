/**
 * @file VideoPlayer.js
 * @created_date Thursday, December 21, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The VideoPlayer component is a React component that renders a video player with various features such as video playback, comments, and annotations. It interacts with an external video player library (VideoJS) to handle video playback and provides additional functionality on top of it.
 *<br/>Breakdown of the code - {@tutorial VideoPlayer}
 * @module VideoPlayer
 *
 **/

import React, { useCallback, useEffect, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";

// This imports the functional component from the previous sample.
import VideoJS from "./VideoJS";
import AnnotationCommentCard from "./AnnotationCommentCard";
import DropDownMenu from "../Misc/Menu";

import "videojs-logo";
import "videojs-logo/dist/videojs-logo.css";
import mediMESH_small_logo from "../../assets/img/workflow_lib/mediMESH-Icon.svg";
import player_poster from "../../assets/img/player/player_poster.jpg";
import PlayerSidebar from "./PlayerSidebar";
import mentionsInputStyle from "../mentionsInputStyle";
import mentionStyle from "../mentionStyle";

import userImage from "../../assets/img/normal_control_icon_user_account.svg";
import MiscFunctions from "../../helpers/MiscFunctions";
import Comment from "../../services/CommentServices";
import WorkflowBar from "./WorkflowBar";
import Segments from "../../services/SegmentsServices";
import Segment from "../../services/SegmentServices";
import Video from "../../services/VideoService";
import userAPI from "../../api/userAPI";
import commentAPI from "../../api/commentAPI";
import videoAPI from "../../api/videoAPI";
import { useNavigate } from "react-router";
import QuickOverviewPopupsContainer from "../IntroductionPopups/QuickOverviewPopupsContainer";
import Ressources from "../../services/RessourcesServices";
import TimelineGlobal from "./TimelineGlobal";
import { getAuthAccessObject } from "../../utils/tokenStorage";
import authAPI from "../../api/authAPI";
import { useTranslation } from "react-i18next";

const CIRCLE_SIZE = 20;

class propertyCommentAnnotation {
  constructor(id, x, y, color, state) {
    this.id = id;
    this.width = "25px";
    this.height = "25px";
    this.borderRadius = "50%";
    this.position = "absolute";
    this.left = x;
    this.top = y;
    this.borderColor = "#4baaac";
    this.borderWidth = "3px";
    this.touchAction = "none";
    this.status = state;
  }
  changeState = (state) => {
    this.status = state;
  };
}

const VideoPlayer = (props) => {
  const loginStatus = getAuthAccessObject();
  if (MiscFunctions.isNull(loginStatus)) {
    authAPI.logout();
  }

  const playerRef = React.useRef(null);
  const containerAnnotationRef = React.useRef(null);

  // State to manage the Video-URL
  const [getVideoURL, setVideoURL] = useState("");
  // State to save the Videscaptions
  const [passVideoCaption, setPassVideoCaption] = useState({});
  // State to capture if the video is currently loading
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  // State to manage the Spinner-symbol
  const [isVideoLoadingSpinner, setIsVideoLoadingSpinner] = useState(true);
  const [UpdateSegments, setUpdateSegments] = useState(true);
  // State to capture the annotations in the video-player
  const [propertyCommentAnnotationArray, setpropertyCommentAnnotationArray] =
    useState([]);
  // State to manage the current comment in creation
  const [isCommentPlaced, setIsCommentPlaced] = useState({
    status: false,
    left: 0,
    top: 0,
    x: 0,
    y: 0,
    time: 0,
    text: "",
    heading: "",
  });
  // State to capture the selected categories inside the comment-creation-card
  const [selectedCategories, setselectedCategories] = useState({});
  const [filterCategories, setfilterCategories] = useState({});

  const [getBoxClientX, setBoxClientX] = useState();
  const [getControlBtnBoxClientX, setControlBtnBoxClientX] = useState();
  const [getAnnotationBeforeAfter, setAnnotationBeforeAfter] = useState("");
  const [getTimeBoxLeftClientX, setTimeBoxLeftClientX] = useState(0);
  const [getClickedTime, setClickedTime] = useState(0);
  const [getBorderSide, setBorderSide] = useState("");
  const [getFlagArrow, setFlagArrow] = useState();
  const [getAnnotationGap, setAnnotationGap] = useState();
  const [getPaddingToBox, setPaddingToBox] = useState();
  const [getCloseComment, setCloseComment] = useState(false);
  const [UserObject, setUserObject] = useState({});
  const [VideoObject, setVideoObject] = useState(null);
  const [CompanyUserObject, setCompanyUserObject] = useState([]);

  const mainVideoID = props.workflow.getVideoID;

  const { t } = useTranslation();

  // State to capture the position of the progress-bar
  const [ProgressBarPosition, setProgressBarPosition] = useState({
    pos: 0,
    time: "0.00",
  });

  const navigate = useNavigate();

  useEffect(() => {
    setBoxClientX(50);
    setControlBtnBoxClientX(405);
    setTimeBoxLeftClientX(5);

    if (props.patientVideo === false) {
      videoAPI
        .getVideoById(mainVideoID)
        .then((result) => {
          setVideoURL(result.media);
          setVideoObject(new Video(result));
        })
        .finally(() => {
          if (isVideoLoadingSpinner) {
            setIsVideoLoading(true);
            setIsVideoLoadingSpinner(false);
          }
        });
    } else {
      setIsVideoLoading(true);
    }
  }, [getVideoURL]);

  const videoJsOptions = {
    preload: true,
    controls: true,
    responsive: true,
    bigPlayButton: false,
    fluid: true,
    poster: player_poster,
    sources: [
      {
        src:
          props.patientVideo === true
            ? props.workflow.getSelectedVideo[0].media
            : getVideoURL,
        type: "video/mp4",
      },
    ],
    controlBar: {
      fullscreenToggle: false,
      pictureInPictureToggle: false,
      remainingTimeDisplay: false,
      volumePanel: true,
      currentTimeDisplay: false,
      timeDivider: false,
      durationDisplay: false,
    },
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    let logoURL = "";
    if (props.patientVideo === false) logoURL = "/workflow_library";
    player
      .logo({
        image: mediMESH_small_logo,
        width: 44,
        height: 42,
        fadeDelay: null,
        position: "top-left",
        url: logoURL,
      })
      .show();

    if (props.PlayerSidebar === true) {
      userAPI
        .getUserObject()
        .then(async (res) => {
          if (res !== false && !MiscFunctions.isUndefined(res)) {
            let User = MiscFunctions.createUserObject(await res);
            setUserObject(User);
            return User;
          }
          return null;
        })
        .then(async (res) => {
          if (!MiscFunctions.isNull(res.UserCompany)) {
            userAPI.getCompanyUsers().then(async (res) => {
              if (!MiscFunctions.isUndefined(res) && !MiscFunctions.isNull(res) && res.length > 0) {
                let userlist = [];
                res.map((elem, i) => {
                  userlist.push({
                    id: elem.id.id,
                    display: elem.firstname + " " + elem.lastname,
                    avatar: !MiscFunctions.isNull(elem.avatar) ? elem.avatar : userImage,
                    type: "user",
                    filterState: false,
                  });
                });
                setCompanyUserObject(userlist);
              }
            });
          } else {
            //Just Add the User
            let userlist = [
              {
                id: res.UserID,
                display: res.display,
                avatar: res.avatar,
                type: "user",
                filterState: false,
              },
            ];
            setCompanyUserObject(userlist);
          }
        });

      videoAPI
        .getVideoInteractionsById(mainVideoID)
        .then(async (res) => {
          // Proper encoding to ensure german umlauts
          const encoder = new TextEncoder();
          const data = encoder.encode(res);
          // Added an ID and the kind to the track
          const track = await playerRef.current.addRemoteTextTrack(
            {
              kind: "metadata",
              src: `data:text/vtt;base64,${window.btoa(
                new Uint8Array(data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              )}`,
              srclang: "en",
              label: "English",
              id: "interactions",
            },
            true
          );

          // Note: Consider to use loadmetadata-callback of the player
          track.addEventListener("load", function () {
            loadTextTracks("interactions", false);
          });
        })
        .finally(() => {
          videoAPI.getVideoProccessById(mainVideoID).then(async (res) => {
            // Added an ID and the kind to the track
            const encoder = new TextEncoder();
            const data = encoder.encode(res);
            await player.addRemoteTextTrack(
              {
                kind: "metadata",
                src: `data:text/vtt;base64,${window.btoa(
                  new Uint8Array(data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ""
                  )
                )}`,
                srclang: "en",
                label: "English",
                id: "process",
              },
              true
            );
          });

          var seekBar = player.controlBar.progressControl.seekBar;
          seekBar.on("mousemove", function (event) {
            var mouseTime =
              player.duration() * seekBar.calculateDistance(event);
            const mouesDisplay =
              document.getElementsByClassName("vjs-mouse-display")[0];
            if (parseInt(mouseTime) === parseInt(player.currentTime())) {
              mouesDisplay.classList.add("vjs-mouse-display-hover");
            }
          });
        });
    }
    player.on("timeupdate", function (e) {
      let elem = {
        pos: player.currentTime() / player.duration(),
        time: (Math.round(player.currentTime() * 100) / 100)
          .toFixed(2)
          .toString(),
      };
      setProgressBarPosition(elem);
    });

    // You can handle player events here, for example:
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
    player.ready(() => {
      let getLogo = document.querySelector(".vjs-logo-content");
      if (!MiscFunctions.isNull(getLogo) && props.patientVideo === false) {
        getLogo.addEventListener("click", function (event) {
          event.preventDefault();
          navigate("/workflow_library");
        });
      }
    });
  };
  // Callback to place the comment
  const placeComment = (e) => {
    // Detect if the pointer is on the right object (Excluding the Play/Pause-Bar f.ex.)
    if (e.target.classList.contains("medimesh-video-player")) {
      playerRef.current.pause();

      let placeComment = {
        status: true,
        left: e.clientX,
        top: e.clientY,
        x: (e.clientX - CIRCLE_SIZE / 2) / e.target.offsetWidth,
        y:
          (e.clientY + document.body.scrollTop - CIRCLE_SIZE / 2) /
          e.target.offsetHeight,
        time: Math.ceil(e.target.currentTime),
        text: "",
        heading: "",
        visibility: "private",
      };

      setClickedTime(
        MiscFunctions.customTimeFormat(Math.ceil(e.target.currentTime))
      );

      if (e.clientX > 750) {
        setBoxClientX(-455);
        setControlBtnBoxClientX(-515);
        setAnnotationBeforeAfter("comment_annotation_circle_before");
        setTimeBoxLeftClientX(-100);
        setBorderSide("right_side_border");
        setFlagArrow(-125);
        setAnnotationGap(0);
        setPaddingToBox(-1);
      } else {
        setBoxClientX(130);
        setControlBtnBoxClientX(500);
        setAnnotationBeforeAfter("comment_annotation_circle_after");
        setTimeBoxLeftClientX(5);
        setBorderSide("left_side_border");
        setFlagArrow(125);
        setAnnotationGap(0);
        setPaddingToBox(1);
      }
      setIsCommentPlaced(placeComment);
      setCloseComment(true);
    }
  };

  // Function will update the Texttracks
  const updateTextTracks = () => {
    videoAPI.getVideoInteractionsById(mainVideoID).then((res) => {
      let trackDel = playerRef.current.textTracks_.tracks_.find(
        (o) => o.id === "interactions"
      );
      console.log("Texttrack to delete:" + trackDel);
      playerRef.current.removeRemoteTextTrack(trackDel);

      const encoder = new TextEncoder();
      const data = encoder.encode(res);

      const track = playerRef.current.addRemoteTextTrack(
        {
          kind: "metadata",
          src: `data:text/vtt;base64,${window.btoa(
            new Uint8Array(data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          )}`,
          srclang: "en",
          label: "English",
          id: "interactions",
        },
        true
      );
      track.addEventListener("load", function () {
        loadTextTracks("interactions", true);
      });
    });
  };

  // Function, which will be executed, if a comment is closed
  // Is resetting all stored values of this comment
  const closeComment = () => {
    let placeComment = {
      status: false,
      left: 0,
      top: 0,
      x: 0,
      y: 0,
      time: 0,
      text: "",
      heading: "",
    };
    setIsCommentPlaced(placeComment);
    setselectedCategories([]);
    setCloseComment(false);
  };

  const [addComment, setAddComment] = useState(null);
  const finishComment = (captchaValue) => {
    // Check if all inputs are complete.
    let isCommentPlacedCopy = isCommentPlaced;

    let pos = [isCommentPlacedCopy.x, isCommentPlacedCopy.y];
    let headline = MiscFunctions.checkCommentText(
      document.getElementById("insert_CommentHead").value
    );
    if (isCommentPlaced.text !== "") {
      let content = MiscFunctions.checkCommentText(isCommentPlaced.text);
      let cat = [];
      if (selectedCategories.length > 0) {
        selectedCategories.map((elem, i) => {
          if (elem.status) {
            cat.push({ id: elem.id, tag: "category" });
          }
        });
      }

      let comment = new Comment({ content: content, headline: headline });

      comment.setVideoID = mainVideoID;
      comment.setUserID = UserObject.UserID;
      comment.setPosition = pos;
      comment.categories = cat;
      comment.setVisibility = "private";

      const resComm = commentAPI.postComment({
        comment: comment.json,
        timestamp: isCommentPlacedCopy.time,
      });
      resComm.then(async (data) => {
        if (data.status === 201) {
          closeComment();
          setAddComment(comment);
        }
      });
    }
  };

  // Function is called, if a change in the Text-field of the comment-creation-card is done
  const CommentText = (ev) => {
    setIsCommentPlaced({ ...isCommentPlaced, text: ev.target.value });
  };
  // Function is called, if a change in the title-field of the comment-creation-card is done
  const CommentHead = (ev) => {
    setIsCommentPlaced({ ...isCommentPlaced, heading: ev.target.value });
  };

  //Function to render the comment-creation-menu inside the videoplayer
  const renderplaceComment = (filterCategories) => {
    let isCommentPlacedCopy = isCommentPlaced;
    // relative positioning of the comments
    let elem = new propertyCommentAnnotation(
      "",
      isCommentPlacedCopy.x * 100 + "%",
      isCommentPlacedCopy.y * 100 + "%",
      "green",
      true
    );

    if (isCommentPlacedCopy.status) {
      return (
        <div>
          <div
            className={getAnnotationBeforeAfter + " comment_annotation_circle"}
            style={{
              transform: "translate(" + getAnnotationGap + "px, 8px)",
              ...elem,
            }}
          ></div>

          <div
            className="comment_time_card_face"
            style={{
              transform: "translate(" + getTimeBoxLeftClientX + "px,40px)",
              left: parseFloat(elem.left) + getPaddingToBox + "%",
              top: elem.top,
            }}
          >
            <p>
              {t("general.time")}: {getClickedTime}
            </p>
          </div>
          <div
            className="flag_arrow"
            style={{
              transform: "translate(" + getFlagArrow + "px,7px) rotate(45deg)",
              left: parseFloat(elem.left) + getPaddingToBox + "%",
              top: elem.top,
              position: "absolute",
            }}
          ></div>

          <div
            className={getBorderSide + " comment_side_box large"}
            style={{
              transform: "translate(" + getBoxClientX + "px,0)",
              width: 350,
              left: parseFloat(elem.left) + getPaddingToBox + "%",
              top: elem.top,
              position: "absolute",
            }}
          >
            <div className="comment_card_face">
              <div className="flex">
                <span className="comment_box_title">
                  <input
                    type="text"
                    name="insert_CommentHead"
                    id="insert_CommentHead"
                    placeholder={"Add a title"}
                    onChange={(ev) => CommentHead(ev)}
                    required
                  />
                </span>
                <span
                  className="comment_box_user_image"
                  style={{
                    background: `rgb(97 97 97 / 9%) url( ${UserObject.avatar})`,
                  }}
                ></span>
              </div>
              <div>
                <DropDownMenu
                  Elements={filterCategories}
                  selectEvent={(input) => {
                    setselectedCategories(input);
                  }}
                />

                <p className="comment_user_display_name">
                  {UserObject.display}
                </p>
              </div>
              <MentionsInput
                placeholder={t("messages.add_comment_message")}
                value={isCommentPlaced.text}
                onChange={(ev) => CommentText(ev)}
                style={mentionsInputStyle}
                a11ySuggestionsListLabel={"Suggested mentions"}
                rows={6}
                className="mention-input"
              >
                <Mention
                  trigger="@"
                  data={CompanyUserObject}
                  displayTransform={(id, display) => `${display}`}
                  style={mentionStyle}
                  markup="@[__display__,__id__]"
                  renderSuggestion={(elem) => {
                    return (
                      <div className="flex flex-row">
                        <div className="suggestion_comment_box_user_image">
                          <span
                            style={{
                              backgroundImage: `url(${elem.avatar})`,
                              width: "25px",
                              height: "25px",
                              display: "block",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              borderRadius: "50%",
                            }}
                          ></span>
                        </div>
                        <div style={{ alignItems: "center", display: "flex" }}>
                          <span>{elem.display}</span>
                        </div>
                      </div>
                    );
                  }}
                />
              </MentionsInput>
            </div>
          </div>
          {/* {Element for closing the object} */}
          <div
            className="close_comment_block"
            style={{
              transform: "translate(" + getControlBtnBoxClientX + "px,0px)",
              left: elem.left,
              top: elem.top,
            }}
            onClick={() => {
              if (getCloseComment) closeComment();
              // var vid = document.getElementById("vjs_video_3_html5_api");
              var vid = document.querySelector(".medimesh-video-player");
              vid.play();
            }}
          >
            <span className="close_comment_box all-category-icon"></span>
          </div>
          {/* {Element for concluding the comment generation} */}
          {
            <div
              className="finish_comment_block"
              style={{
                transform: "translate(" + getControlBtnBoxClientX + "px,45px)",
                left: elem.left,
                top: elem.top,
              }}
              onClick={() => {
                finishComment();
              }}
            >
              <span className="finish_comment_box all-category-icon"></span>
            </div>
          }
        </div>
      );
    }
  };

  function loadTextTracks(tag, init) {
    const cueList = playerRef.current
      .textTracks()
      .tracks_.find((o) => o.id === tag).cues_;
    // Added callback for the Cue-Events
    let track = playerRef.current
      .textTracks()
      .tracks_.find((o) => o.id === tag);
    track.oncuechange = () => {
      let actCue = track.activeCues;
      if (actCue.length !== 0) {
        const AnnotationArray = [];
        for (let i = 0; i < actCue.length; i++) {
          let dummy;
          try {
            dummy = JSON.parse(actCue[i].text);
            let pos = dummy.position;
            let check = document.getElementById("comm_" + dummy.id);
            if (MiscFunctions.isNull(check)) {
              AnnotationArray.push(
                new propertyCommentAnnotation(
                  dummy.id,
                  pos[0] * 100 + "%",
                  pos[1] * 100 + "%",
                  "green",
                  false
                )
              );
            } else {
              AnnotationArray.push(
                new propertyCommentAnnotation(
                  dummy.id,
                  pos[0] * 100 + "%",
                  pos[1] * 100 + "%",
                  "green",
                  true
                )
              );
              console.log(true);
            }
          } catch (error) {
            console.log(error);
          }
        }
        setpropertyCommentAnnotationArray(AnnotationArray);
      } else {
        const AnnotationArray = [];
        setpropertyCommentAnnotationArray(AnnotationArray);
      }
    };
    let allTextTracks = [];
    cueList.forEach((el, i) => {
      let texttrack = JSON.parse(el.text);
      allTextTracks.push(texttrack);
    });
    setPassVideoCaption(allTextTracks);
  }

  //Callback to update the counter in the comment-card
  const updateCounter = (elem, passVideoCaption) => {
    const newCaptions = passVideoCaption.map((obj, index) => {
      if (obj.id === elem.id) {
        return { ...obj, children: parseInt(obj.children) + 1 };
      } else {
        return obj;
      }
    });

    console.log(newCaptions);
    // Setzen des neuen Zustand
    setPassVideoCaption(newCaptions);
  };

  const ToggleUpdate = () => {
    console.log("Test..........");
    setUpdateSegments(!UpdateSegments);
  };

  const [showIntroPopup, setShowIntroPoup] = useState(
    props.QuickOverviewPopups ? true : false
  );
  function handleCloseIntroPopup(state) {
    setShowIntroPoup(state);
  }
  function handleOpenIntroPopup() {
    setShowIntroPoup(true);
  }

  const [ressources, setRessources] = useState([]);
  const [persons, setPersons] = useState([]);
  const [personsGroup, setPersonsGroup] = useState([]);
  useEffect(() => {
    if (!MiscFunctions.isNull(VideoObject) || !MiscFunctions.isNull(mainVideoID)) {
      videoAPI
        .getVideoRessourcesById(
          !MiscFunctions.isNull(VideoObject) ? VideoObject.getVideoID : mainVideoID
        )
        .then((response) => {
          let ressources = response;
          setRessources(ressources);
          const Resources = new Ressources(ressources);
          let persons = Resources.getPersons;
          let personsGroup = Resources.getPersonGroup;
          setPersons(persons);
          setPersonsGroup(personsGroup);
        });
    }
  }, [VideoObject]);

  const workflowParameters = {
    person: persons,
    persongroup: personsGroup,
  };

  const [WorkflowPhases, setWorkflowPhases] = useState();
  const [WorkflowTasks, setWorkflowTasks] = useState();
  // Fetch Phases for allocating to the related compnents (Sidebar, Workflowbar, ...)
  useEffect(() => {
    setfilterCategories(props.workflow.getCategories);

    const videoID = mainVideoID;
    videoAPI.getWorkflowPhasesJsonById(videoID).then((response) => {
      if (MiscFunctions.isUndefined(response)) return;
      if (!MiscFunctions.isNull(response.length)) {
        let phasesSegments = new Segments([]);
        response.forEach((response, i) => {
          let segmentPhase = new Segment();
          segmentPhase.assignData = response;
          phasesSegments.addSegment = segmentPhase;
        });
        setWorkflowPhases(phasesSegments);
      } else {
        setWorkflowPhases(new Segments([]));
      }
    });

    videoAPI.getWorkflowTasksJsonById(videoID).then((responses) => {
      if (MiscFunctions.isUndefined(responses)) return;
      if (!MiscFunctions.isNull(responses.length)) {
        let tasksSegments = new Segments([]);
        responses.forEach((response, i) => {
          let segmentTasks = new Segment();
          segmentTasks.assignData = response;
          tasksSegments.addSegment = segmentTasks;
        });
        setWorkflowTasks(tasksSegments);
      } else {
        setWorkflowTasks(new Segments([]));
      }
    });
  }, [UpdateSegments]);

  const [zoomLevel, setZoomLevel] = useState(1);
  const [rangeSliderValue, setRangeSliderValue] = useState([0, 1]);

  const handlingZoomScroll = (newZoomLevel, rangeValue) => {
    if (MiscFunctions.isNull(newZoomLevel)) {
      setRangeSliderValue(rangeValue);
    } else {
      setZoomLevel(newZoomLevel);
      setRangeSliderValue(rangeValue);
    }
  };
  useEffect(() => {
    setZoomLevel(zoomLevel);
    setRangeSliderValue(rangeSliderValue);
  }, [zoomLevel, rangeSliderValue]);
  const SETTINGS = {
    PlayerSidebar: props.PlayerSidebar,
    QuickOverviewPopups: props.QuickOverviewPopups,
    addComment: props.addComment,
  };

  const [containerHeight, setContainerHeight] = useState(450);
  useEffect(() => {
    function handleResize() {
      if (containerAnnotationRef.current) {
        const contentAdmin = 40;
        const commentSearch = 35;
        const commentFilter = 35;
        const SumHeight = contentAdmin + commentSearch + commentFilter;

        setContainerHeight(containerAnnotationRef.current.clientHeight - SumHeight);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isVideoLoading]);

  return (
    <>
      {!MiscFunctions.isUndefined(props.workflow) &&
        !MiscFunctions.isNull(VideoObject) &&
        SETTINGS.QuickOverviewPopups && (
          <QuickOverviewPopupsContainer
            workflow={props.workflow}
            VideoObject={VideoObject}
            showIntroPopup={showIntroPopup}
            handleCloseIntroPopup={handleCloseIntroPopup}
            ressources={ressources}
            personsGroup={personsGroup}
          />
        )}

      {isVideoLoading && (
        <div className="main_player_block flex-wrap">
          <div className="player_slider_blocks">
            <div
              className="video_player_block"
              onPointerDown={(e) => {
                placeComment(e);
              }}
              id="video_block"
            >
              <div
                className="container_annotation"
                ref={containerAnnotationRef}
              >
                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
                {
                  // Function to render the comment-creation-menu, as it was requested to place a comment inside the videoplayern
                  SETTINGS.addComment && renderplaceComment(filterCategories)
                }
                {
                  // Fetching the corresponding comment
                  propertyCommentAnnotationArray.map((elem, i) => {
                    return (
                      <AnnotationCommentCard
                        elem={elem}
                        passVideoCaption={passVideoCaption}
                        user={UserObject}
                        companyUsers={CompanyUserObject}
                        videoID={mainVideoID}
                        key={elem.id}
                        onReply={() => updateCounter(elem, passVideoCaption)}
                        closeComment={() => closeComment()}
                        containerAnnotationRef={containerAnnotationRef}
                        player={playerRef}
                      />
                    );
                  })
                }
              </div>
              {!MiscFunctions.isNull(playerRef.current) && (
                <TimelineGlobal
                  player={playerRef}
                  handlingZoomScroll={(newZoomLevel, rangeValue) => {
                    handlingZoomScroll(newZoomLevel, rangeValue);
                  }}
                  zoomLevel={zoomLevel}
                  rangeSliderValue={rangeSliderValue}
                  ProgressBarPosition={ProgressBarPosition}
                />
              )}
            </div>
            <div
              className="player_sidebar pt-3 pr-6 pl-2"
              onClick={() => {
                if (getCloseComment) closeComment();
              }}
            >
              {SETTINGS.PlayerSidebar && (
                <PlayerSidebar
                  UserObject={UserObject}
                  companyUsers={CompanyUserObject}
                  player={playerRef}
                  filterCategories={filterCategories}
                  workflow={props.workflow}
                  processSteps={WorkflowPhases}
                  tasks={WorkflowTasks}
                  addComment={addComment}
                  UpdateSegments={() => {
                    ToggleUpdate();
                  }}
                  containerHeight={containerHeight}
                />
              )}
            </div>
          </div>
          <div
            className="container_process flex-col"
            onClick={() => {
              if (getCloseComment) closeComment();
            }}
          >
            {!MiscFunctions.isNull(playerRef.current) && (
              <div>
                <WorkflowBar
                  workflowParameters={workflowParameters}
                  WorkflowPhases={WorkflowPhases}
                  WorkflowTasks={WorkflowTasks}
                  videoLabel={props.workflow.label}
                  player={playerRef}
                  ProgressBarPosition={ProgressBarPosition}
                  showIntroPopup={showIntroPopup}
                  handleOpenIntroPopup={handleOpenIntroPopup}
                  handlingZoomScroll={(newZoomLevel, rangeValue) => {
                    handlingZoomScroll(newZoomLevel, rangeValue);
                  }}
                  videoID={mainVideoID}
                  selectedVideo={props.workflow.selectedVideo}
                  zoomLevel={zoomLevel}
                  rangeSliderValue={rangeSliderValue}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default VideoPlayer;
