/**
 * @file CommentCard.js
 * @created_date Monday, August 14, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description Thiscomponent is named CommentCard, which is designed for displaying and interacting with comment in a player interface.
 *<br/>Breakdown of the code - {@tutorial CommentCard}
 * @module CommentCard
 *
 **/

import React from "react";
import { Tooltip } from "flowbite-react";
import Avatar from "../Misc/Avatar";
import { Icon } from "../Misc/Icon";
import MiscFunctions from "../../helpers/MiscFunctions";
import { withTranslation } from "react-i18next";

class CommentCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: this.props.comment,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.comment !== this.props.comment) {
      this.setState({ comment: this.props.comment });
    }
  }

  componentWillUnmount() {
    this.setState({ comment: null });
  }

  renderCommentDropDown = () => {
    if (!this.state.showHideTextBlock) {
      return (
        // arrow-down-icon
        <div className="arrow-up-block">
          <span className="icon_up all-category-icon"></span>
        </div>
      );
    } else {
      return (
        // arrow-up-icon
        <div className="arrow-down-block">
          <span className="icon_down all-category-icon"></span>
        </div>
      );
    }
  };

  clickComment = (comment, player) => {
    MiscFunctions.gotoTimestamp(comment.getTimeStampSeconds, player);
    var intervalID = setInterval(function () {
      let check = document.getElementById("ann_" + comment.getID);
      if (!MiscFunctions.isNull(check)) {
        clearInterval(intervalID);
        check.classList.add("FadeInAndOut");

        check.addEventListener("animationend", function () {
          check.classList.remove("FadeInAndOut");
        });
      }
    }, 300);
  };

  hoverProcessStep = (process) => {
    let check = document.getElementById("process_" + process.getID);
    var intervalID = setInterval(function () {
      if (!MiscFunctions.isNull(check)) {
        clearInterval(intervalID);
        var i = 0;
        if (!check.classList.contains("FadeInAndOut_Border"))
          check.classList.add("FadeInAndOut_Border");
        else check.classList.remove("FadeInAndOut_Border");

        if (++i === 4) {
          window.clearInterval(intervalID);
          check.classList.remove("FadeInAndOut_Border");
        }
      }
    }, 300);
    check.classList.remove("FadeInAndOut_Border");
  };

  render() {
    return (
      <div
        className={"mt-3 comment_side_box left_side_border comment_side_box_transition"}
        key={this.state.comment.getID}
        onClick={() => {
          setTimeout(() => {
            this.setState((prevState) => ({
              showHideTextBlock: !prevState.showHideTextBlock,
            }));
          }, 300)
        }}
      >
        <div
          className={"comment_card "}
          id={"comment_card_" + this.state.comment.getID}
        >
          <div className="comment_card_face">
            <div className="flex gap-2">
              <div className="comment_box_title_container">
                <div className="comment_box_title">
                  <Tooltip content={this.state.comment.getHeadline}>
                    {this.state.comment.getHeadline}
                  </Tooltip>
                </div>
              </div>
              <Avatar
                avatar={this.state.comment.getUserAvatar}
                name={this.state.comment.getUserName}
              />
            </div>
            <div className="comment_card_sub_details">
              <div
                className="comment_box_title_process_step"
                onMouseEnter={() => {
                  // this.hoverProcessStep(this.props.process);
                }}
              >
                {this.props.process.getLabel}
              </div>
              <div className="comment_box_time">
                {MiscFunctions.customTimeFormat(
                  Math.ceil(this.state.comment.getTimeStampSeconds)
                )}
              </div>
              <div className="comment_box_username">
                {this.state.comment.getUserName}
              </div>
            </div>
            <div className="flex comments_cats">
              {/* Rendering the categories of the comment */}
              {this.state.comment.getCategories.map((cat, j) => {
                return (
                  //p-1 mx-1
                  <Icon category={cat} key={j} />
                );
              })}
              {/* Rendering the Play-Button */}
              <div
                className="flex icon_play_box"
                onClick={() => {
                  this.clickComment(this.state.comment, this.props.player);
                }}
              >
                {/* Play-Icon */}
                <span className="icon_play all-category-icon"></span>
              </div>

              <div className="comment_reply_box">
                <span className="icon_comment_reply all-category-icon">
                  <span className="comment_reply_conter">
                    {this.state.comment.getNumberOfReplies}
                  </span>
                </span>
              </div>
              {this.renderCommentDropDown()}
            </div>
            {this.state.showHideTextBlock && (
              <div className="mt-3 flex" key={this.state.comment.id}>
                <div className="comment_box_text">
                  {MiscFunctions.formatCommentText(
                    this.state.comment.getContent
                  )}
                  <div className="comment_box_date_box">
                    <div className="comment_box_created">
                      {this.props.t('general.created') + ": " + this.state.comment.getDateCreatedFormat}
                    </div>
                    <div className="comment_box_changed">
                      {this.props.t('general.changed') + ": " + this.state.comment.getDateChangedFormat}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CommentCard);
