/**
 *
 * @file notifyAPI.js
 * @created_date Friday, July 28, 2023
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description Here we defined a set of functions related to notification authentication for the application, These functions are organized within the notifyAPI object, which is then exported for use in other parts of the application. The code also includes the import of utility functions for managing access tokens and an API client.
 * <br/>Example use age of code - {@tutorial notifyAPI}
 * @module notifyAPI
 *
 **/

import apiClient from "../utils/apiClient";
import { getAuthAccessObject } from "../utils/tokenStorage";

/**
 * @description  This function sends a PUT request to the /user/notification/:id endpoint to mark a notification as read. It includes the access token in the headers. If the request is successful, it returns the response as a parsed JSON object or false if the response includes "not found".
 * <br />Here you can find some examples: - {@tutorial notifyAPI}
 * @function notify
 * @return {void}
 * */
const notify = async (id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: getAuthAccessObject().recaptcha,
    };
    return await apiClient
      .put(`/user/notification/${id}`, "", customHeaders)
      .then(async function (response) {
        const data = await JSON.parse(response);
        if (response.status === 404) {
          return false;
        } else {
          return data;
        }
        
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const notifyAPI = {
  notify,
};

export default notifyAPI;
