/**
 *
 * @file persongroupAPI.js
 * @created_date Friday, July 28, 2023
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description Here we defined a set of functions related to persongroup authentication for the application, These functions are organized within the persongroupAPI object, which is then exported for use in other parts of the application. The code also includes the import of utility functions for managing access tokens and an API client.
 * <br/>Example use age of code - {@tutorial persongroupAPI}
 * @module persongroupAPI
 *
 **/

import apiClient from "../utils/apiClient";
import PersongroupService from "../services/PersongroupService"
import { getAuthAccessObject } from "../utils/tokenStorage";

/**
 * @description This function sends a POST request to the /segment endpoint to post a segment. It includes the access token and reCAPTCHA value in the headers. It returns the response object.
 * <br />Here you can find some examples: - {@tutorial persongroupAPI}
 * @function postSegment
 * @return {void}
 * */
const postPersonGroup = async (captchaValue, data) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: getAuthAccessObject().recaptcha,
    };

    return await apiClient
      .post(`/persongroup`, data, customHeaders)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const getPersonGroups = async () => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(`/persongroup/list`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          
          const data = resp.map((elem) => {
            return new PersongroupService(elem)});

          console.log(data)
          return data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const persongroupAPI = {
  postPersonGroup,
  getPersonGroups,
};

export default persongroupAPI;
