import { toast } from "react-toastify";

const successMessage = (message) =>
    toast.success(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });


const erorrMessage = (message) =>
    toast.error(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });


const ToastMessages = {
    successMessage,
    erorrMessage
}

export default ToastMessages;