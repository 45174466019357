/**
 * @file Dashboard.js
 * @created_date Thursday, December 21, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description Dashboard componenet is responsible for rendering the main dashboard view of the web application. It imports and utilizes several other components, including CheckLogin, VideoPlayer, and IntroductionPopup.
 *<br/>Breakdown of the code - {@tutorial CheckLogin}
 * @module Dashboard
 *
 **/

import React from "react";
import CheckLogin from "./CheckLogin";
import VideoPlayer from "./Player/VideoPlayer";
import Workflow from "../services/WorkflowService";
import categoryAPI from "../api/categoryAPI";
import Category from "../services/CategoryServices";
import authAPI from "../api/authAPI";
import MiscFunctions from "../helpers/MiscFunctions";
class Dashboard extends React.Component {
  constructor() {
    super();

    this.selectedWorkflowItem = JSON.parse(
      localStorage.getItem("selected_workflow_item")
    );

    this.patientVideoItem = JSON.parse(
      localStorage.getItem("patient_video_item")
    );

    this.patientVideoStatus =
    !MiscFunctions.isNull(this.patientVideoItem) ? this.patientVideoItem.status : false;

    if (this.patientVideoStatus !== true) {
      
      const data = {
        id:
          !MiscFunctions.isNull(this.selectedWorkflowItem)
            ? this.selectedWorkflowItem.id
            : authAPI.logout(),
        label: this.selectedWorkflowItem.label,
        categories: [],
        selectedVideo:
          !MiscFunctions.isNull(this.selectedWorkflowItem.selectedVideo.id)
            ? this.selectedWorkflowItem.selectedVideo
            : null,
        description: this.selectedWorkflowItem.description,
        introPopupShown: null,
      };
      const workflow = new Workflow(data);
      this.state = {
        workflow: workflow,
        patientVideo: false,
        PlayerSidebar: true,
        QuickOverviewPopups: true,
        addComment: true,
      };
    } else {
      const data = {
        label: this.patientVideoItem.data.label,
        id: this.patientVideoItem.data.id,
        selectedVideo: this.patientVideoItem.data,
      };
      const workflow = new Workflow(data);
      this.state = {
        workflow: workflow,
        patientVideo: true,
        PlayerSidebar: false,
        QuickOverviewPopups: false,
        addComment: false,
      };
    }
  }
  handleStorage = () => {
    this.setState({
      introPopupShown: localStorage.getItem("hasSeenIntroductionPopup"),
    });
  };
  componentDidMount() {
    if (this.patientVideoStatus !== true) {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });

      window.addEventListener("storage", this.handleStorage);
      this.setState({
        introPopupShown: localStorage.getItem("hasSeenIntroductionPopup"),
      });
      categoryAPI.getCategories().then((res) => {
        let Categories = res.map((elem) => {
          let cat = new Category(elem);
          return cat;
        });

        this.state.workflow.categories.push(...Categories);
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.workflow !== this.state.workflow) {
      this.setState({ workflow: this.state.workflow });
    }
  }

  render() {
    return (
      <div className="dashboard-scrollable">
        <div className="h-screen" style={{ background: "#f2f2f2" }}>
          <CheckLogin />
          {/* {this.patientVideoStatus !== true && <IntroductionPopup />}*/}
          <div className="all_sections_block">
            <VideoPlayer
              workflow={this.state.workflow}
              patientVideo={this.state.patientVideo}
              PlayerSidebar={this.state.PlayerSidebar}
              QuickOverviewPopups={this.state.QuickOverviewPopups}
              addComment={this.state.addComment}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
