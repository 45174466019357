const checkOverflow = (elementRef) => {
  const elem = elementRef.current;
  if (elem) {
    if (elem.scrollWidth > elem.offsetWidth) {
      return true;
    } else {
      return false;
    }
  }
};

const horizontalScroll = (containerRef) => {
  let touchStartX = 0;

  function handleWheel(e) {
    if (containerRef.current) {
      const container = containerRef.current;
      if (e.deltaY > 0) {
        container.scrollLeft += 100;
      } else {
        container.scrollLeft -= 100;
      }
      e.preventDefault();
    }
  }

  function handleTouchStart(e) {
    if (e.touches.length === 1) {
      touchStartX = e.touches[0].clientX;
    }
  }

  function handleTouchMove(e) {
    if (containerRef.current && e.touches.length === 1) {
      const container = containerRef.current;
      const touchEndX = e.touches[0].clientX;
      const deltaX = touchStartX - touchEndX;
      container.scrollLeft += deltaX;
      touchStartX = touchEndX; // Update touchStartX for continuous touch movement
      e.preventDefault();
    }
  }

  if (containerRef.current) {
    const container = containerRef.current;
    container.addEventListener("wheel", handleWheel);
    container.addEventListener("touchstart", handleTouchStart);
    container.addEventListener("touchmove", handleTouchMove);
  }

  return function removeHorizontalScrollListener() {
    if (containerRef.current) {
      const container = containerRef.current;
      container.removeEventListener("wheel", handleWheel);
      container.removeEventListener("touchstart", handleTouchStart);
      container.removeEventListener("touchmove", handleTouchMove);
    }
  };
};


const ElementLayoutHelpers = {
  checkOverflow,
  horizontalScroll,
};

export default ElementLayoutHelpers;
